import { default as indexTL50S0xoIpMeta } from "/usr/src/webshop/pages/index.vue?macro=true";
import { default as about_45usmU71aRvcExMeta } from "/usr/src/webshop/pages/about-us.vue?macro=true";
import { default as callbackTyUxbO3sXKMeta } from "/usr/src/webshop/pages/account/login/callback.vue?macro=true";
import { default as _91slug_93zHy9E8P4kXMeta } from "/usr/src/webshop/pages/account/order/[slug].vue?macro=true";
import { default as overview6S6UcIkD9kMeta } from "/usr/src/webshop/pages/account/overview.vue?macro=true";
import { default as recovery53mECIUVKPMeta } from "/usr/src/webshop/pages/account/recovery.vue?macro=true";
import { default as reset_45passwordcdZ80Fko61Meta } from "/usr/src/webshop/pages/account/reset-password.vue?macro=true";
import { default as adyen_45paymentQ79ZCNRwXBMeta } from "/usr/src/webshop/pages/adyen-payment.vue?macro=true";
import { default as _91slug_93eEDSkUBoTAMeta } from "/usr/src/webshop/pages/author/[slug].vue?macro=true";
import { default as index9AOP056g8xMeta } from "/usr/src/webshop/pages/author/index.vue?macro=true";
import { default as _91slug_93oThgs0MTeaMeta } from "/usr/src/webshop/pages/blog/[slug].vue?macro=true";
import { default as booklet_45labels_45design_45detailsCaI536S7avMeta } from "/usr/src/webshop/pages/booklet-labels-design-details.vue?macro=true";
import { default as booklet_45labels_45form4xxCsD0gjZMeta } from "/usr/src/webshop/pages/booklet-labels-form.vue?macro=true";
import { default as _91slug_93q6mH35oQJGMeta } from "/usr/src/webshop/pages/category/[slug].vue?macro=true";
import { default as indexlf0bE1W4kIMeta } from "/usr/src/webshop/pages/category/index.vue?macro=true";
import { default as consultantsUS9B4Vl7L7Meta } from "/usr/src/webshop/pages/consultants.vue?macro=true";
import { default as contactHxomGztE4QMeta } from "/usr/src/webshop/pages/contact.vue?macro=true";
import { default as _91slug_93a7AbIdmEQpMeta } from "/usr/src/webshop/pages/content/[slug].vue?macro=true";
import { default as custom_45made_45shipping_45boxes_45formgCirt8vucYMeta } from "/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue?macro=true";
import { default as _91slug_93RLpY3YbddOMeta } from "/usr/src/webshop/pages/data-check/[slug].vue?macro=true";
import { default as indexAubJnXmWUPMeta } from "/usr/src/webshop/pages/data-check/index.vue?macro=true";
import { default as deliveryyxkY4RZzldMeta } from "/usr/src/webshop/pages/delivery.vue?macro=true";
import { default as dictionaryusXI9VIn8wMeta } from "/usr/src/webshop/pages/dictionary.vue?macro=true";
import { default as digiboxyPJTG21D8LMeta } from "/usr/src/webshop/pages/digibox.vue?macro=true";
import { default as _91slug_93WEsvOGNwk4Meta } from "/usr/src/webshop/pages/faq/[slug].vue?macro=true";
import { default as index5hW08pKmg9Meta } from "/usr/src/webshop/pages/faq/index.vue?macro=true";
import { default as folding_45boxes_45formQzFineVXRWMeta } from "/usr/src/webshop/pages/folding-boxes-form.vue?macro=true";
import { default as food_45labels_45informationsBpJhdsdahoMeta } from "/usr/src/webshop/pages/food-labels-informations.vue?macro=true";
import { default as healthzmq4s5YxpPOMeta } from "/usr/src/webshop/pages/healthz.vue?macro=true";
import { default as impressumvxN7EZ7dlsMeta } from "/usr/src/webshop/pages/impressum.vue?macro=true";
import { default as _91slug_93bR1a6iu5ugMeta } from "/usr/src/webshop/pages/jobs/[slug].vue?macro=true";
import { default as indexlTzws98g0aMeta } from "/usr/src/webshop/pages/jobs/index.vue?macro=true";
import { default as laminate_45tubes_45formUKwcMmr0EGMeta } from "/usr/src/webshop/pages/laminate-tubes-form.vue?macro=true";
import { default as loginleSlZWr5SQMeta } from "/usr/src/webshop/pages/login.vue?macro=true";
import { default as machinesVeXazlSxTyMeta } from "/usr/src/webshop/pages/machines.vue?macro=true";
import { default as _91slug_93fzgGhaud8PMeta } from "/usr/src/webshop/pages/magazine/[slug].vue?macro=true";
import { default as indexIfH8CgOMpuMeta } from "/usr/src/webshop/pages/magazine/index.vue?macro=true";
import { default as major_45customers_45contactQ37ZPONBD8Meta } from "/usr/src/webshop/pages/major-customers-contact.vue?macro=true";
import { default as major_45customerscpQ7Z3YH2sMeta } from "/usr/src/webshop/pages/major-customers.vue?macro=true";
import { default as _91slug_93dpFQ9Uko3kMeta } from "/usr/src/webshop/pages/materials/[slug].vue?macro=true";
import { default as index2YHJwghYq8Meta } from "/usr/src/webshop/pages/materials/index.vue?macro=true";
import { default as mellerudgG5o4pwLWlMeta } from "/usr/src/webshop/pages/mellerud.vue?macro=true";
import { default as mymueslikGUV7ALafiMeta } from "/usr/src/webshop/pages/mymuesli.vue?macro=true";
import { default as mymusliklQxaYiXkXMeta } from "/usr/src/webshop/pages/mymusli.vue?macro=true";
import { default as newsletter_45confirmationLwkJHJjyJyMeta } from "/usr/src/webshop/pages/newsletter-confirmation.vue?macro=true";
import { default as addressRRdvIUxF45Meta } from "/usr/src/webshop/pages/order/address.vue?macro=true";
import { default as cardjUA5ZTsqEKMeta } from "/usr/src/webshop/pages/order/card.vue?macro=true";
import { default as login0VG7pLfhqfMeta } from "/usr/src/webshop/pages/order/login.vue?macro=true";
import { default as overviewDpi4KRAgfiMeta } from "/usr/src/webshop/pages/order/overview.vue?macro=true";
import { default as printdatawCBp4soi4sMeta } from "/usr/src/webshop/pages/order/printdata.vue?macro=true";
import { default as shipping8QRsAaIan2Meta } from "/usr/src/webshop/pages/order/shipping.vue?macro=true";
import { default as thank_45youw64uWVYufPMeta } from "/usr/src/webshop/pages/order/thank-you.vue?macro=true";
import { default as our_45promiseQiurQDHWlIMeta } from "/usr/src/webshop/pages/our-promise.vue?macro=true";
import { default as package_45leaflets_45package_45inserts_45formEt80PNDmspMeta } from "/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue?macro=true";
import { default as paypal_listeneryOeCsNs1r8Meta } from "/usr/src/webshop/pages/paypal/paypal_listener.vue?macro=true";
import { default as index41xcvZ4TrPMeta } from "/usr/src/webshop/pages/print-data/index.vue?macro=true";
import { default as repairknkkhZuJPqMeta } from "/usr/src/webshop/pages/print-data/repair.vue?macro=true";
import { default as printed_45labels_45formJKMDMMt0yFMeta } from "/usr/src/webshop/pages/printed-labels-form.vue?macro=true";
import { default as privacy_45policy2WYyyO2BhYMeta } from "/usr/src/webshop/pages/privacy-policy.vue?macro=true";
import { default as _91slug_93UhSj1oq5uGMeta } from "/usr/src/webshop/pages/products/[slug].vue?macro=true";
import { default as indexBALeFK7cDEMeta } from "/usr/src/webshop/pages/products/index.vue?macro=true";
import { default as redirect_45to_45paypal2SSWCgGFSdMeta } from "/usr/src/webshop/pages/redirect-to-paypal.vue?macro=true";
import { default as sample_45corrugated_45cardboardbq31GwdGTVMeta } from "/usr/src/webshop/pages/sample-corrugated-cardboard.vue?macro=true";
import { default as sampleVA9t0Y69h6Meta } from "/usr/src/webshop/pages/sample.vue?macro=true";
import { default as sampletube9hKvUuyhbuMeta } from "/usr/src/webshop/pages/sampletube.vue?macro=true";
import { default as sitemapQ4FiwpGrEUMeta } from "/usr/src/webshop/pages/sitemap.vue?macro=true";
import { default as sustainabilityv05wKT300EMeta } from "/usr/src/webshop/pages/sustainability.vue?macro=true";
import { default as swiss_45fairWuCOb43gXvMeta } from "/usr/src/webshop/pages/swiss-fair.vue?macro=true";
import { default as terms_45of_45servicekkgjABgitLMeta } from "/usr/src/webshop/pages/terms-of-service.vue?macro=true";
export default [
  {
    name: indexTL50S0xoIpMeta?.name ?? "index",
    path: indexTL50S0xoIpMeta?.path ?? "/",
    meta: indexTL50S0xoIpMeta || {},
    alias: indexTL50S0xoIpMeta?.alias || [],
    redirect: indexTL50S0xoIpMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: about_45usmU71aRvcExMeta?.name ?? "about-us___de",
    path: about_45usmU71aRvcExMeta?.path ?? "/de/about-us",
    meta: about_45usmU71aRvcExMeta || {},
    alias: about_45usmU71aRvcExMeta?.alias || [],
    redirect: about_45usmU71aRvcExMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45usmU71aRvcExMeta?.name ?? "about-us___en",
    path: about_45usmU71aRvcExMeta?.path ?? "/en/about-us",
    meta: about_45usmU71aRvcExMeta || {},
    alias: about_45usmU71aRvcExMeta?.alias || [],
    redirect: about_45usmU71aRvcExMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45usmU71aRvcExMeta?.name ?? "about-us___fr",
    path: about_45usmU71aRvcExMeta?.path ?? "/fr/about-us",
    meta: about_45usmU71aRvcExMeta || {},
    alias: about_45usmU71aRvcExMeta?.alias || [],
    redirect: about_45usmU71aRvcExMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45usmU71aRvcExMeta?.name ?? "about-us___cs",
    path: about_45usmU71aRvcExMeta?.path ?? "/cs/about-us",
    meta: about_45usmU71aRvcExMeta || {},
    alias: about_45usmU71aRvcExMeta?.alias || [],
    redirect: about_45usmU71aRvcExMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45usmU71aRvcExMeta?.name ?? "about-us___it",
    path: about_45usmU71aRvcExMeta?.path ?? "/it/about-us",
    meta: about_45usmU71aRvcExMeta || {},
    alias: about_45usmU71aRvcExMeta?.alias || [],
    redirect: about_45usmU71aRvcExMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45usmU71aRvcExMeta?.name ?? "about-us___pl",
    path: about_45usmU71aRvcExMeta?.path ?? "/pl/about-us",
    meta: about_45usmU71aRvcExMeta || {},
    alias: about_45usmU71aRvcExMeta?.alias || [],
    redirect: about_45usmU71aRvcExMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: callbackTyUxbO3sXKMeta?.name ?? "account-login-callback___de",
    path: callbackTyUxbO3sXKMeta?.path ?? "/de/account/login/callback",
    meta: callbackTyUxbO3sXKMeta || {},
    alias: callbackTyUxbO3sXKMeta?.alias || [],
    redirect: callbackTyUxbO3sXKMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/login/callback.vue").then(m => m.default || m)
  },
  {
    name: callbackTyUxbO3sXKMeta?.name ?? "account-login-callback___en",
    path: callbackTyUxbO3sXKMeta?.path ?? "/en/account/login/callback",
    meta: callbackTyUxbO3sXKMeta || {},
    alias: callbackTyUxbO3sXKMeta?.alias || [],
    redirect: callbackTyUxbO3sXKMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/login/callback.vue").then(m => m.default || m)
  },
  {
    name: callbackTyUxbO3sXKMeta?.name ?? "account-login-callback___fr",
    path: callbackTyUxbO3sXKMeta?.path ?? "/fr/account/login/callback",
    meta: callbackTyUxbO3sXKMeta || {},
    alias: callbackTyUxbO3sXKMeta?.alias || [],
    redirect: callbackTyUxbO3sXKMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/login/callback.vue").then(m => m.default || m)
  },
  {
    name: callbackTyUxbO3sXKMeta?.name ?? "account-login-callback___cs",
    path: callbackTyUxbO3sXKMeta?.path ?? "/cs/account/login/callback",
    meta: callbackTyUxbO3sXKMeta || {},
    alias: callbackTyUxbO3sXKMeta?.alias || [],
    redirect: callbackTyUxbO3sXKMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/login/callback.vue").then(m => m.default || m)
  },
  {
    name: callbackTyUxbO3sXKMeta?.name ?? "account-login-callback___it",
    path: callbackTyUxbO3sXKMeta?.path ?? "/it/account/login/callback",
    meta: callbackTyUxbO3sXKMeta || {},
    alias: callbackTyUxbO3sXKMeta?.alias || [],
    redirect: callbackTyUxbO3sXKMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/login/callback.vue").then(m => m.default || m)
  },
  {
    name: callbackTyUxbO3sXKMeta?.name ?? "account-login-callback___pl",
    path: callbackTyUxbO3sXKMeta?.path ?? "/pl/account/login/callback",
    meta: callbackTyUxbO3sXKMeta || {},
    alias: callbackTyUxbO3sXKMeta?.alias || [],
    redirect: callbackTyUxbO3sXKMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/login/callback.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zHy9E8P4kXMeta?.name ?? "account-order-slug___de",
    path: _91slug_93zHy9E8P4kXMeta?.path ?? "/de/account/order/:slug()",
    meta: _91slug_93zHy9E8P4kXMeta || {},
    alias: _91slug_93zHy9E8P4kXMeta?.alias || [],
    redirect: _91slug_93zHy9E8P4kXMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zHy9E8P4kXMeta?.name ?? "account-order-slug___en",
    path: _91slug_93zHy9E8P4kXMeta?.path ?? "/en/account/order/:slug()",
    meta: _91slug_93zHy9E8P4kXMeta || {},
    alias: _91slug_93zHy9E8P4kXMeta?.alias || [],
    redirect: _91slug_93zHy9E8P4kXMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zHy9E8P4kXMeta?.name ?? "account-order-slug___fr",
    path: _91slug_93zHy9E8P4kXMeta?.path ?? "/fr/account/order/:slug()",
    meta: _91slug_93zHy9E8P4kXMeta || {},
    alias: _91slug_93zHy9E8P4kXMeta?.alias || [],
    redirect: _91slug_93zHy9E8P4kXMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zHy9E8P4kXMeta?.name ?? "account-order-slug___cs",
    path: _91slug_93zHy9E8P4kXMeta?.path ?? "/cs/account/order/:slug()",
    meta: _91slug_93zHy9E8P4kXMeta || {},
    alias: _91slug_93zHy9E8P4kXMeta?.alias || [],
    redirect: _91slug_93zHy9E8P4kXMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zHy9E8P4kXMeta?.name ?? "account-order-slug___it",
    path: _91slug_93zHy9E8P4kXMeta?.path ?? "/it/account/order/:slug()",
    meta: _91slug_93zHy9E8P4kXMeta || {},
    alias: _91slug_93zHy9E8P4kXMeta?.alias || [],
    redirect: _91slug_93zHy9E8P4kXMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zHy9E8P4kXMeta?.name ?? "account-order-slug___pl",
    path: _91slug_93zHy9E8P4kXMeta?.path ?? "/pl/account/order/:slug()",
    meta: _91slug_93zHy9E8P4kXMeta || {},
    alias: _91slug_93zHy9E8P4kXMeta?.alias || [],
    redirect: _91slug_93zHy9E8P4kXMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: overview6S6UcIkD9kMeta?.name ?? "account-overview___de",
    path: overview6S6UcIkD9kMeta?.path ?? "/de/account/overview",
    meta: overview6S6UcIkD9kMeta || {},
    alias: overview6S6UcIkD9kMeta?.alias || [],
    redirect: overview6S6UcIkD9kMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/overview.vue").then(m => m.default || m)
  },
  {
    name: overview6S6UcIkD9kMeta?.name ?? "account-overview___en",
    path: overview6S6UcIkD9kMeta?.path ?? "/en/account/overview",
    meta: overview6S6UcIkD9kMeta || {},
    alias: overview6S6UcIkD9kMeta?.alias || [],
    redirect: overview6S6UcIkD9kMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/overview.vue").then(m => m.default || m)
  },
  {
    name: overview6S6UcIkD9kMeta?.name ?? "account-overview___fr",
    path: overview6S6UcIkD9kMeta?.path ?? "/fr/account/overview",
    meta: overview6S6UcIkD9kMeta || {},
    alias: overview6S6UcIkD9kMeta?.alias || [],
    redirect: overview6S6UcIkD9kMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/overview.vue").then(m => m.default || m)
  },
  {
    name: overview6S6UcIkD9kMeta?.name ?? "account-overview___cs",
    path: overview6S6UcIkD9kMeta?.path ?? "/cs/account/overview",
    meta: overview6S6UcIkD9kMeta || {},
    alias: overview6S6UcIkD9kMeta?.alias || [],
    redirect: overview6S6UcIkD9kMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/overview.vue").then(m => m.default || m)
  },
  {
    name: overview6S6UcIkD9kMeta?.name ?? "account-overview___it",
    path: overview6S6UcIkD9kMeta?.path ?? "/it/account/overview",
    meta: overview6S6UcIkD9kMeta || {},
    alias: overview6S6UcIkD9kMeta?.alias || [],
    redirect: overview6S6UcIkD9kMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/overview.vue").then(m => m.default || m)
  },
  {
    name: overview6S6UcIkD9kMeta?.name ?? "account-overview___pl",
    path: overview6S6UcIkD9kMeta?.path ?? "/pl/account/overview",
    meta: overview6S6UcIkD9kMeta || {},
    alias: overview6S6UcIkD9kMeta?.alias || [],
    redirect: overview6S6UcIkD9kMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/overview.vue").then(m => m.default || m)
  },
  {
    name: recovery53mECIUVKPMeta?.name ?? "account-recovery___de",
    path: recovery53mECIUVKPMeta?.path ?? "/de/account/recovery",
    meta: recovery53mECIUVKPMeta || {},
    alias: recovery53mECIUVKPMeta?.alias || [],
    redirect: recovery53mECIUVKPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: recovery53mECIUVKPMeta?.name ?? "account-recovery___en",
    path: recovery53mECIUVKPMeta?.path ?? "/en/account/recovery",
    meta: recovery53mECIUVKPMeta || {},
    alias: recovery53mECIUVKPMeta?.alias || [],
    redirect: recovery53mECIUVKPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: recovery53mECIUVKPMeta?.name ?? "account-recovery___fr",
    path: recovery53mECIUVKPMeta?.path ?? "/fr/account/recovery",
    meta: recovery53mECIUVKPMeta || {},
    alias: recovery53mECIUVKPMeta?.alias || [],
    redirect: recovery53mECIUVKPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: recovery53mECIUVKPMeta?.name ?? "account-recovery___cs",
    path: recovery53mECIUVKPMeta?.path ?? "/cs/account/recovery",
    meta: recovery53mECIUVKPMeta || {},
    alias: recovery53mECIUVKPMeta?.alias || [],
    redirect: recovery53mECIUVKPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: recovery53mECIUVKPMeta?.name ?? "account-recovery___it",
    path: recovery53mECIUVKPMeta?.path ?? "/it/account/recovery",
    meta: recovery53mECIUVKPMeta || {},
    alias: recovery53mECIUVKPMeta?.alias || [],
    redirect: recovery53mECIUVKPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: recovery53mECIUVKPMeta?.name ?? "account-recovery___pl",
    path: recovery53mECIUVKPMeta?.path ?? "/pl/account/recovery",
    meta: recovery53mECIUVKPMeta || {},
    alias: recovery53mECIUVKPMeta?.alias || [],
    redirect: recovery53mECIUVKPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/recovery.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordcdZ80Fko61Meta?.name ?? "account-reset-password___de",
    path: reset_45passwordcdZ80Fko61Meta?.path ?? "/de/account/reset-password",
    meta: reset_45passwordcdZ80Fko61Meta || {},
    alias: reset_45passwordcdZ80Fko61Meta?.alias || [],
    redirect: reset_45passwordcdZ80Fko61Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordcdZ80Fko61Meta?.name ?? "account-reset-password___en",
    path: reset_45passwordcdZ80Fko61Meta?.path ?? "/en/account/reset-password",
    meta: reset_45passwordcdZ80Fko61Meta || {},
    alias: reset_45passwordcdZ80Fko61Meta?.alias || [],
    redirect: reset_45passwordcdZ80Fko61Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordcdZ80Fko61Meta?.name ?? "account-reset-password___fr",
    path: reset_45passwordcdZ80Fko61Meta?.path ?? "/fr/account/reset-password",
    meta: reset_45passwordcdZ80Fko61Meta || {},
    alias: reset_45passwordcdZ80Fko61Meta?.alias || [],
    redirect: reset_45passwordcdZ80Fko61Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordcdZ80Fko61Meta?.name ?? "account-reset-password___cs",
    path: reset_45passwordcdZ80Fko61Meta?.path ?? "/cs/account/reset-password",
    meta: reset_45passwordcdZ80Fko61Meta || {},
    alias: reset_45passwordcdZ80Fko61Meta?.alias || [],
    redirect: reset_45passwordcdZ80Fko61Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordcdZ80Fko61Meta?.name ?? "account-reset-password___it",
    path: reset_45passwordcdZ80Fko61Meta?.path ?? "/it/account/reset-password",
    meta: reset_45passwordcdZ80Fko61Meta || {},
    alias: reset_45passwordcdZ80Fko61Meta?.alias || [],
    redirect: reset_45passwordcdZ80Fko61Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordcdZ80Fko61Meta?.name ?? "account-reset-password___pl",
    path: reset_45passwordcdZ80Fko61Meta?.path ?? "/pl/account/reset-password",
    meta: reset_45passwordcdZ80Fko61Meta || {},
    alias: reset_45passwordcdZ80Fko61Meta?.alias || [],
    redirect: reset_45passwordcdZ80Fko61Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: adyen_45paymentQ79ZCNRwXBMeta?.name ?? "adyen-payment___de",
    path: adyen_45paymentQ79ZCNRwXBMeta?.path ?? "/de/adyen-payment",
    meta: adyen_45paymentQ79ZCNRwXBMeta || {},
    alias: adyen_45paymentQ79ZCNRwXBMeta?.alias || [],
    redirect: adyen_45paymentQ79ZCNRwXBMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/adyen-payment.vue").then(m => m.default || m)
  },
  {
    name: adyen_45paymentQ79ZCNRwXBMeta?.name ?? "adyen-payment___en",
    path: adyen_45paymentQ79ZCNRwXBMeta?.path ?? "/en/adyen-payment",
    meta: adyen_45paymentQ79ZCNRwXBMeta || {},
    alias: adyen_45paymentQ79ZCNRwXBMeta?.alias || [],
    redirect: adyen_45paymentQ79ZCNRwXBMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/adyen-payment.vue").then(m => m.default || m)
  },
  {
    name: adyen_45paymentQ79ZCNRwXBMeta?.name ?? "adyen-payment___fr",
    path: adyen_45paymentQ79ZCNRwXBMeta?.path ?? "/fr/adyen-payment",
    meta: adyen_45paymentQ79ZCNRwXBMeta || {},
    alias: adyen_45paymentQ79ZCNRwXBMeta?.alias || [],
    redirect: adyen_45paymentQ79ZCNRwXBMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/adyen-payment.vue").then(m => m.default || m)
  },
  {
    name: adyen_45paymentQ79ZCNRwXBMeta?.name ?? "adyen-payment___cs",
    path: adyen_45paymentQ79ZCNRwXBMeta?.path ?? "/cs/adyen-payment",
    meta: adyen_45paymentQ79ZCNRwXBMeta || {},
    alias: adyen_45paymentQ79ZCNRwXBMeta?.alias || [],
    redirect: adyen_45paymentQ79ZCNRwXBMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/adyen-payment.vue").then(m => m.default || m)
  },
  {
    name: adyen_45paymentQ79ZCNRwXBMeta?.name ?? "adyen-payment___it",
    path: adyen_45paymentQ79ZCNRwXBMeta?.path ?? "/it/adyen-payment",
    meta: adyen_45paymentQ79ZCNRwXBMeta || {},
    alias: adyen_45paymentQ79ZCNRwXBMeta?.alias || [],
    redirect: adyen_45paymentQ79ZCNRwXBMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/adyen-payment.vue").then(m => m.default || m)
  },
  {
    name: adyen_45paymentQ79ZCNRwXBMeta?.name ?? "adyen-payment___pl",
    path: adyen_45paymentQ79ZCNRwXBMeta?.path ?? "/pl/adyen-payment",
    meta: adyen_45paymentQ79ZCNRwXBMeta || {},
    alias: adyen_45paymentQ79ZCNRwXBMeta?.alias || [],
    redirect: adyen_45paymentQ79ZCNRwXBMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/adyen-payment.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eEDSkUBoTAMeta?.name ?? "author-slug___de",
    path: _91slug_93eEDSkUBoTAMeta?.path ?? "/de/author/:slug()",
    meta: _91slug_93eEDSkUBoTAMeta || {},
    alias: _91slug_93eEDSkUBoTAMeta?.alias || [],
    redirect: _91slug_93eEDSkUBoTAMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eEDSkUBoTAMeta?.name ?? "author-slug___en",
    path: _91slug_93eEDSkUBoTAMeta?.path ?? "/en/author/:slug()",
    meta: _91slug_93eEDSkUBoTAMeta || {},
    alias: _91slug_93eEDSkUBoTAMeta?.alias || [],
    redirect: _91slug_93eEDSkUBoTAMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eEDSkUBoTAMeta?.name ?? "author-slug___fr",
    path: _91slug_93eEDSkUBoTAMeta?.path ?? "/fr/author/:slug()",
    meta: _91slug_93eEDSkUBoTAMeta || {},
    alias: _91slug_93eEDSkUBoTAMeta?.alias || [],
    redirect: _91slug_93eEDSkUBoTAMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eEDSkUBoTAMeta?.name ?? "author-slug___cs",
    path: _91slug_93eEDSkUBoTAMeta?.path ?? "/cs/author/:slug()",
    meta: _91slug_93eEDSkUBoTAMeta || {},
    alias: _91slug_93eEDSkUBoTAMeta?.alias || [],
    redirect: _91slug_93eEDSkUBoTAMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eEDSkUBoTAMeta?.name ?? "author-slug___it",
    path: _91slug_93eEDSkUBoTAMeta?.path ?? "/it/author/:slug()",
    meta: _91slug_93eEDSkUBoTAMeta || {},
    alias: _91slug_93eEDSkUBoTAMeta?.alias || [],
    redirect: _91slug_93eEDSkUBoTAMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eEDSkUBoTAMeta?.name ?? "author-slug___pl",
    path: _91slug_93eEDSkUBoTAMeta?.path ?? "/pl/author/:slug()",
    meta: _91slug_93eEDSkUBoTAMeta || {},
    alias: _91slug_93eEDSkUBoTAMeta?.alias || [],
    redirect: _91slug_93eEDSkUBoTAMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: index9AOP056g8xMeta?.name ?? "author___de",
    path: index9AOP056g8xMeta?.path ?? "/de/author",
    meta: index9AOP056g8xMeta || {},
    alias: index9AOP056g8xMeta?.alias || [],
    redirect: index9AOP056g8xMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: index9AOP056g8xMeta?.name ?? "author___en",
    path: index9AOP056g8xMeta?.path ?? "/en/author",
    meta: index9AOP056g8xMeta || {},
    alias: index9AOP056g8xMeta?.alias || [],
    redirect: index9AOP056g8xMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: index9AOP056g8xMeta?.name ?? "author___fr",
    path: index9AOP056g8xMeta?.path ?? "/fr/author",
    meta: index9AOP056g8xMeta || {},
    alias: index9AOP056g8xMeta?.alias || [],
    redirect: index9AOP056g8xMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: index9AOP056g8xMeta?.name ?? "author___cs",
    path: index9AOP056g8xMeta?.path ?? "/cs/author",
    meta: index9AOP056g8xMeta || {},
    alias: index9AOP056g8xMeta?.alias || [],
    redirect: index9AOP056g8xMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: index9AOP056g8xMeta?.name ?? "author___it",
    path: index9AOP056g8xMeta?.path ?? "/it/author",
    meta: index9AOP056g8xMeta || {},
    alias: index9AOP056g8xMeta?.alias || [],
    redirect: index9AOP056g8xMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: index9AOP056g8xMeta?.name ?? "author___pl",
    path: index9AOP056g8xMeta?.path ?? "/pl/author",
    meta: index9AOP056g8xMeta || {},
    alias: index9AOP056g8xMeta?.alias || [],
    redirect: index9AOP056g8xMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oThgs0MTeaMeta?.name ?? "blog-slug___de",
    path: _91slug_93oThgs0MTeaMeta?.path ?? "/de/blog/:slug()",
    meta: _91slug_93oThgs0MTeaMeta || {},
    alias: _91slug_93oThgs0MTeaMeta?.alias || [],
    redirect: _91slug_93oThgs0MTeaMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oThgs0MTeaMeta?.name ?? "blog-slug___en",
    path: _91slug_93oThgs0MTeaMeta?.path ?? "/en/blog/:slug()",
    meta: _91slug_93oThgs0MTeaMeta || {},
    alias: _91slug_93oThgs0MTeaMeta?.alias || [],
    redirect: _91slug_93oThgs0MTeaMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oThgs0MTeaMeta?.name ?? "blog-slug___fr",
    path: _91slug_93oThgs0MTeaMeta?.path ?? "/fr/blog/:slug()",
    meta: _91slug_93oThgs0MTeaMeta || {},
    alias: _91slug_93oThgs0MTeaMeta?.alias || [],
    redirect: _91slug_93oThgs0MTeaMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oThgs0MTeaMeta?.name ?? "blog-slug___cs",
    path: _91slug_93oThgs0MTeaMeta?.path ?? "/cs/blog/:slug()",
    meta: _91slug_93oThgs0MTeaMeta || {},
    alias: _91slug_93oThgs0MTeaMeta?.alias || [],
    redirect: _91slug_93oThgs0MTeaMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oThgs0MTeaMeta?.name ?? "blog-slug___it",
    path: _91slug_93oThgs0MTeaMeta?.path ?? "/it/blog/:slug()",
    meta: _91slug_93oThgs0MTeaMeta || {},
    alias: _91slug_93oThgs0MTeaMeta?.alias || [],
    redirect: _91slug_93oThgs0MTeaMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oThgs0MTeaMeta?.name ?? "blog-slug___pl",
    path: _91slug_93oThgs0MTeaMeta?.path ?? "/pl/blog/:slug()",
    meta: _91slug_93oThgs0MTeaMeta || {},
    alias: _91slug_93oThgs0MTeaMeta?.alias || [],
    redirect: _91slug_93oThgs0MTeaMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: booklet_45labels_45design_45detailsCaI536S7avMeta?.name ?? "booklet-labels-design-details___de",
    path: booklet_45labels_45design_45detailsCaI536S7avMeta?.path ?? "/de/booklet-labels-design-details",
    meta: booklet_45labels_45design_45detailsCaI536S7avMeta || {},
    alias: booklet_45labels_45design_45detailsCaI536S7avMeta?.alias || [],
    redirect: booklet_45labels_45design_45detailsCaI536S7avMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/booklet-labels-design-details.vue").then(m => m.default || m)
  },
  {
    name: booklet_45labels_45design_45detailsCaI536S7avMeta?.name ?? "booklet-labels-design-details___en",
    path: booklet_45labels_45design_45detailsCaI536S7avMeta?.path ?? "/en/booklet-labels-design-details",
    meta: booklet_45labels_45design_45detailsCaI536S7avMeta || {},
    alias: booklet_45labels_45design_45detailsCaI536S7avMeta?.alias || [],
    redirect: booklet_45labels_45design_45detailsCaI536S7avMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/booklet-labels-design-details.vue").then(m => m.default || m)
  },
  {
    name: booklet_45labels_45design_45detailsCaI536S7avMeta?.name ?? "booklet-labels-design-details___fr",
    path: booklet_45labels_45design_45detailsCaI536S7avMeta?.path ?? "/fr/booklet-labels-design-details",
    meta: booklet_45labels_45design_45detailsCaI536S7avMeta || {},
    alias: booklet_45labels_45design_45detailsCaI536S7avMeta?.alias || [],
    redirect: booklet_45labels_45design_45detailsCaI536S7avMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/booklet-labels-design-details.vue").then(m => m.default || m)
  },
  {
    name: booklet_45labels_45design_45detailsCaI536S7avMeta?.name ?? "booklet-labels-design-details___cs",
    path: booklet_45labels_45design_45detailsCaI536S7avMeta?.path ?? "/cs/booklet-labels-design-details",
    meta: booklet_45labels_45design_45detailsCaI536S7avMeta || {},
    alias: booklet_45labels_45design_45detailsCaI536S7avMeta?.alias || [],
    redirect: booklet_45labels_45design_45detailsCaI536S7avMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/booklet-labels-design-details.vue").then(m => m.default || m)
  },
  {
    name: booklet_45labels_45design_45detailsCaI536S7avMeta?.name ?? "booklet-labels-design-details___it",
    path: booklet_45labels_45design_45detailsCaI536S7avMeta?.path ?? "/it/booklet-labels-design-details",
    meta: booklet_45labels_45design_45detailsCaI536S7avMeta || {},
    alias: booklet_45labels_45design_45detailsCaI536S7avMeta?.alias || [],
    redirect: booklet_45labels_45design_45detailsCaI536S7avMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/booklet-labels-design-details.vue").then(m => m.default || m)
  },
  {
    name: booklet_45labels_45design_45detailsCaI536S7avMeta?.name ?? "booklet-labels-design-details___pl",
    path: booklet_45labels_45design_45detailsCaI536S7avMeta?.path ?? "/pl/booklet-labels-design-details",
    meta: booklet_45labels_45design_45detailsCaI536S7avMeta || {},
    alias: booklet_45labels_45design_45detailsCaI536S7avMeta?.alias || [],
    redirect: booklet_45labels_45design_45detailsCaI536S7avMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/booklet-labels-design-details.vue").then(m => m.default || m)
  },
  {
    name: booklet_45labels_45form4xxCsD0gjZMeta?.name ?? "booklet-labels-form___de",
    path: booklet_45labels_45form4xxCsD0gjZMeta?.path ?? "/de/booklet-labels-form",
    meta: booklet_45labels_45form4xxCsD0gjZMeta || {},
    alias: booklet_45labels_45form4xxCsD0gjZMeta?.alias || [],
    redirect: booklet_45labels_45form4xxCsD0gjZMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/booklet-labels-form.vue").then(m => m.default || m)
  },
  {
    name: booklet_45labels_45form4xxCsD0gjZMeta?.name ?? "booklet-labels-form___en",
    path: booklet_45labels_45form4xxCsD0gjZMeta?.path ?? "/en/booklet-labels-form",
    meta: booklet_45labels_45form4xxCsD0gjZMeta || {},
    alias: booklet_45labels_45form4xxCsD0gjZMeta?.alias || [],
    redirect: booklet_45labels_45form4xxCsD0gjZMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/booklet-labels-form.vue").then(m => m.default || m)
  },
  {
    name: booklet_45labels_45form4xxCsD0gjZMeta?.name ?? "booklet-labels-form___fr",
    path: booklet_45labels_45form4xxCsD0gjZMeta?.path ?? "/fr/booklet-labels-form",
    meta: booklet_45labels_45form4xxCsD0gjZMeta || {},
    alias: booklet_45labels_45form4xxCsD0gjZMeta?.alias || [],
    redirect: booklet_45labels_45form4xxCsD0gjZMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/booklet-labels-form.vue").then(m => m.default || m)
  },
  {
    name: booklet_45labels_45form4xxCsD0gjZMeta?.name ?? "booklet-labels-form___cs",
    path: booklet_45labels_45form4xxCsD0gjZMeta?.path ?? "/cs/booklet-labels-form",
    meta: booklet_45labels_45form4xxCsD0gjZMeta || {},
    alias: booklet_45labels_45form4xxCsD0gjZMeta?.alias || [],
    redirect: booklet_45labels_45form4xxCsD0gjZMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/booklet-labels-form.vue").then(m => m.default || m)
  },
  {
    name: booklet_45labels_45form4xxCsD0gjZMeta?.name ?? "booklet-labels-form___it",
    path: booklet_45labels_45form4xxCsD0gjZMeta?.path ?? "/it/booklet-labels-form",
    meta: booklet_45labels_45form4xxCsD0gjZMeta || {},
    alias: booklet_45labels_45form4xxCsD0gjZMeta?.alias || [],
    redirect: booklet_45labels_45form4xxCsD0gjZMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/booklet-labels-form.vue").then(m => m.default || m)
  },
  {
    name: booklet_45labels_45form4xxCsD0gjZMeta?.name ?? "booklet-labels-form___pl",
    path: booklet_45labels_45form4xxCsD0gjZMeta?.path ?? "/pl/booklet-labels-form",
    meta: booklet_45labels_45form4xxCsD0gjZMeta || {},
    alias: booklet_45labels_45form4xxCsD0gjZMeta?.alias || [],
    redirect: booklet_45labels_45form4xxCsD0gjZMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/booklet-labels-form.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q6mH35oQJGMeta?.name ?? "category-slug___de",
    path: _91slug_93q6mH35oQJGMeta?.path ?? "/de/category/:slug()",
    meta: _91slug_93q6mH35oQJGMeta || {},
    alias: _91slug_93q6mH35oQJGMeta?.alias || [],
    redirect: _91slug_93q6mH35oQJGMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q6mH35oQJGMeta?.name ?? "category-slug___en",
    path: _91slug_93q6mH35oQJGMeta?.path ?? "/en/category/:slug()",
    meta: _91slug_93q6mH35oQJGMeta || {},
    alias: _91slug_93q6mH35oQJGMeta?.alias || [],
    redirect: _91slug_93q6mH35oQJGMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q6mH35oQJGMeta?.name ?? "category-slug___fr",
    path: _91slug_93q6mH35oQJGMeta?.path ?? "/fr/category/:slug()",
    meta: _91slug_93q6mH35oQJGMeta || {},
    alias: _91slug_93q6mH35oQJGMeta?.alias || [],
    redirect: _91slug_93q6mH35oQJGMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q6mH35oQJGMeta?.name ?? "category-slug___cs",
    path: _91slug_93q6mH35oQJGMeta?.path ?? "/cs/category/:slug()",
    meta: _91slug_93q6mH35oQJGMeta || {},
    alias: _91slug_93q6mH35oQJGMeta?.alias || [],
    redirect: _91slug_93q6mH35oQJGMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q6mH35oQJGMeta?.name ?? "category-slug___it",
    path: _91slug_93q6mH35oQJGMeta?.path ?? "/it/category/:slug()",
    meta: _91slug_93q6mH35oQJGMeta || {},
    alias: _91slug_93q6mH35oQJGMeta?.alias || [],
    redirect: _91slug_93q6mH35oQJGMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93q6mH35oQJGMeta?.name ?? "category-slug___pl",
    path: _91slug_93q6mH35oQJGMeta?.path ?? "/pl/category/:slug()",
    meta: _91slug_93q6mH35oQJGMeta || {},
    alias: _91slug_93q6mH35oQJGMeta?.alias || [],
    redirect: _91slug_93q6mH35oQJGMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexlf0bE1W4kIMeta?.name ?? "category___de",
    path: indexlf0bE1W4kIMeta?.path ?? "/de/category",
    meta: indexlf0bE1W4kIMeta || {},
    alias: indexlf0bE1W4kIMeta?.alias || [],
    redirect: indexlf0bE1W4kIMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexlf0bE1W4kIMeta?.name ?? "category___en",
    path: indexlf0bE1W4kIMeta?.path ?? "/en/category",
    meta: indexlf0bE1W4kIMeta || {},
    alias: indexlf0bE1W4kIMeta?.alias || [],
    redirect: indexlf0bE1W4kIMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexlf0bE1W4kIMeta?.name ?? "category___fr",
    path: indexlf0bE1W4kIMeta?.path ?? "/fr/category",
    meta: indexlf0bE1W4kIMeta || {},
    alias: indexlf0bE1W4kIMeta?.alias || [],
    redirect: indexlf0bE1W4kIMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexlf0bE1W4kIMeta?.name ?? "category___cs",
    path: indexlf0bE1W4kIMeta?.path ?? "/cs/category",
    meta: indexlf0bE1W4kIMeta || {},
    alias: indexlf0bE1W4kIMeta?.alias || [],
    redirect: indexlf0bE1W4kIMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexlf0bE1W4kIMeta?.name ?? "category___it",
    path: indexlf0bE1W4kIMeta?.path ?? "/it/category",
    meta: indexlf0bE1W4kIMeta || {},
    alias: indexlf0bE1W4kIMeta?.alias || [],
    redirect: indexlf0bE1W4kIMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: indexlf0bE1W4kIMeta?.name ?? "category___pl",
    path: indexlf0bE1W4kIMeta?.path ?? "/pl/category",
    meta: indexlf0bE1W4kIMeta || {},
    alias: indexlf0bE1W4kIMeta?.alias || [],
    redirect: indexlf0bE1W4kIMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/category/index.vue").then(m => m.default || m)
  },
  {
    name: consultantsUS9B4Vl7L7Meta?.name ?? "consultants___de",
    path: consultantsUS9B4Vl7L7Meta?.path ?? "/de/consultants",
    meta: consultantsUS9B4Vl7L7Meta || {},
    alias: consultantsUS9B4Vl7L7Meta?.alias || [],
    redirect: consultantsUS9B4Vl7L7Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/consultants.vue").then(m => m.default || m)
  },
  {
    name: consultantsUS9B4Vl7L7Meta?.name ?? "consultants___en",
    path: consultantsUS9B4Vl7L7Meta?.path ?? "/en/consultants",
    meta: consultantsUS9B4Vl7L7Meta || {},
    alias: consultantsUS9B4Vl7L7Meta?.alias || [],
    redirect: consultantsUS9B4Vl7L7Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/consultants.vue").then(m => m.default || m)
  },
  {
    name: consultantsUS9B4Vl7L7Meta?.name ?? "consultants___fr",
    path: consultantsUS9B4Vl7L7Meta?.path ?? "/fr/consultants",
    meta: consultantsUS9B4Vl7L7Meta || {},
    alias: consultantsUS9B4Vl7L7Meta?.alias || [],
    redirect: consultantsUS9B4Vl7L7Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/consultants.vue").then(m => m.default || m)
  },
  {
    name: consultantsUS9B4Vl7L7Meta?.name ?? "consultants___cs",
    path: consultantsUS9B4Vl7L7Meta?.path ?? "/cs/consultants",
    meta: consultantsUS9B4Vl7L7Meta || {},
    alias: consultantsUS9B4Vl7L7Meta?.alias || [],
    redirect: consultantsUS9B4Vl7L7Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/consultants.vue").then(m => m.default || m)
  },
  {
    name: consultantsUS9B4Vl7L7Meta?.name ?? "consultants___it",
    path: consultantsUS9B4Vl7L7Meta?.path ?? "/it/consultants",
    meta: consultantsUS9B4Vl7L7Meta || {},
    alias: consultantsUS9B4Vl7L7Meta?.alias || [],
    redirect: consultantsUS9B4Vl7L7Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/consultants.vue").then(m => m.default || m)
  },
  {
    name: consultantsUS9B4Vl7L7Meta?.name ?? "consultants___pl",
    path: consultantsUS9B4Vl7L7Meta?.path ?? "/pl/consultants",
    meta: consultantsUS9B4Vl7L7Meta || {},
    alias: consultantsUS9B4Vl7L7Meta?.alias || [],
    redirect: consultantsUS9B4Vl7L7Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/consultants.vue").then(m => m.default || m)
  },
  {
    name: contactHxomGztE4QMeta?.name ?? "contact___de",
    path: contactHxomGztE4QMeta?.path ?? "/de/contact",
    meta: contactHxomGztE4QMeta || {},
    alias: contactHxomGztE4QMeta?.alias || [],
    redirect: contactHxomGztE4QMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactHxomGztE4QMeta?.name ?? "contact___en",
    path: contactHxomGztE4QMeta?.path ?? "/en/contact",
    meta: contactHxomGztE4QMeta || {},
    alias: contactHxomGztE4QMeta?.alias || [],
    redirect: contactHxomGztE4QMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactHxomGztE4QMeta?.name ?? "contact___fr",
    path: contactHxomGztE4QMeta?.path ?? "/fr/contact",
    meta: contactHxomGztE4QMeta || {},
    alias: contactHxomGztE4QMeta?.alias || [],
    redirect: contactHxomGztE4QMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactHxomGztE4QMeta?.name ?? "contact___cs",
    path: contactHxomGztE4QMeta?.path ?? "/cs/contact",
    meta: contactHxomGztE4QMeta || {},
    alias: contactHxomGztE4QMeta?.alias || [],
    redirect: contactHxomGztE4QMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactHxomGztE4QMeta?.name ?? "contact___it",
    path: contactHxomGztE4QMeta?.path ?? "/it/contact",
    meta: contactHxomGztE4QMeta || {},
    alias: contactHxomGztE4QMeta?.alias || [],
    redirect: contactHxomGztE4QMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactHxomGztE4QMeta?.name ?? "contact___pl",
    path: contactHxomGztE4QMeta?.path ?? "/pl/contact",
    meta: contactHxomGztE4QMeta || {},
    alias: contactHxomGztE4QMeta?.alias || [],
    redirect: contactHxomGztE4QMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93a7AbIdmEQpMeta?.name ?? "content-slug___de",
    path: _91slug_93a7AbIdmEQpMeta?.path ?? "/de/content/:slug()",
    meta: _91slug_93a7AbIdmEQpMeta || {},
    alias: _91slug_93a7AbIdmEQpMeta?.alias || [],
    redirect: _91slug_93a7AbIdmEQpMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93a7AbIdmEQpMeta?.name ?? "content-slug___en",
    path: _91slug_93a7AbIdmEQpMeta?.path ?? "/en/content/:slug()",
    meta: _91slug_93a7AbIdmEQpMeta || {},
    alias: _91slug_93a7AbIdmEQpMeta?.alias || [],
    redirect: _91slug_93a7AbIdmEQpMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93a7AbIdmEQpMeta?.name ?? "content-slug___fr",
    path: _91slug_93a7AbIdmEQpMeta?.path ?? "/fr/content/:slug()",
    meta: _91slug_93a7AbIdmEQpMeta || {},
    alias: _91slug_93a7AbIdmEQpMeta?.alias || [],
    redirect: _91slug_93a7AbIdmEQpMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93a7AbIdmEQpMeta?.name ?? "content-slug___cs",
    path: _91slug_93a7AbIdmEQpMeta?.path ?? "/cs/content/:slug()",
    meta: _91slug_93a7AbIdmEQpMeta || {},
    alias: _91slug_93a7AbIdmEQpMeta?.alias || [],
    redirect: _91slug_93a7AbIdmEQpMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93a7AbIdmEQpMeta?.name ?? "content-slug___it",
    path: _91slug_93a7AbIdmEQpMeta?.path ?? "/it/content/:slug()",
    meta: _91slug_93a7AbIdmEQpMeta || {},
    alias: _91slug_93a7AbIdmEQpMeta?.alias || [],
    redirect: _91slug_93a7AbIdmEQpMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93a7AbIdmEQpMeta?.name ?? "content-slug___pl",
    path: _91slug_93a7AbIdmEQpMeta?.path ?? "/pl/content/:slug()",
    meta: _91slug_93a7AbIdmEQpMeta || {},
    alias: _91slug_93a7AbIdmEQpMeta?.alias || [],
    redirect: _91slug_93a7AbIdmEQpMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.name ?? "custom-made-shipping-boxes-form___de",
    path: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.path ?? "/de/custom-made-shipping-boxes-form",
    meta: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta || {},
    alias: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.alias || [],
    redirect: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.name ?? "custom-made-shipping-boxes-form___en",
    path: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.path ?? "/en/custom-made-shipping-boxes-form",
    meta: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta || {},
    alias: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.alias || [],
    redirect: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.name ?? "custom-made-shipping-boxes-form___fr",
    path: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.path ?? "/fr/custom-made-shipping-boxes-form",
    meta: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta || {},
    alias: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.alias || [],
    redirect: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.name ?? "custom-made-shipping-boxes-form___cs",
    path: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.path ?? "/cs/custom-made-shipping-boxes-form",
    meta: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta || {},
    alias: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.alias || [],
    redirect: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.name ?? "custom-made-shipping-boxes-form___it",
    path: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.path ?? "/it/custom-made-shipping-boxes-form",
    meta: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta || {},
    alias: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.alias || [],
    redirect: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.name ?? "custom-made-shipping-boxes-form___pl",
    path: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.path ?? "/pl/custom-made-shipping-boxes-form",
    meta: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta || {},
    alias: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.alias || [],
    redirect: custom_45made_45shipping_45boxes_45formgCirt8vucYMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/custom-made-shipping-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RLpY3YbddOMeta?.name ?? "data-check-slug___de",
    path: _91slug_93RLpY3YbddOMeta?.path ?? "/de/data-check/:slug()",
    meta: _91slug_93RLpY3YbddOMeta || {},
    alias: _91slug_93RLpY3YbddOMeta?.alias || [],
    redirect: _91slug_93RLpY3YbddOMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/data-check/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RLpY3YbddOMeta?.name ?? "data-check-slug___en",
    path: _91slug_93RLpY3YbddOMeta?.path ?? "/en/data-check/:slug()",
    meta: _91slug_93RLpY3YbddOMeta || {},
    alias: _91slug_93RLpY3YbddOMeta?.alias || [],
    redirect: _91slug_93RLpY3YbddOMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/data-check/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RLpY3YbddOMeta?.name ?? "data-check-slug___fr",
    path: _91slug_93RLpY3YbddOMeta?.path ?? "/fr/data-check/:slug()",
    meta: _91slug_93RLpY3YbddOMeta || {},
    alias: _91slug_93RLpY3YbddOMeta?.alias || [],
    redirect: _91slug_93RLpY3YbddOMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/data-check/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RLpY3YbddOMeta?.name ?? "data-check-slug___cs",
    path: _91slug_93RLpY3YbddOMeta?.path ?? "/cs/data-check/:slug()",
    meta: _91slug_93RLpY3YbddOMeta || {},
    alias: _91slug_93RLpY3YbddOMeta?.alias || [],
    redirect: _91slug_93RLpY3YbddOMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/data-check/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RLpY3YbddOMeta?.name ?? "data-check-slug___it",
    path: _91slug_93RLpY3YbddOMeta?.path ?? "/it/data-check/:slug()",
    meta: _91slug_93RLpY3YbddOMeta || {},
    alias: _91slug_93RLpY3YbddOMeta?.alias || [],
    redirect: _91slug_93RLpY3YbddOMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/data-check/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RLpY3YbddOMeta?.name ?? "data-check-slug___pl",
    path: _91slug_93RLpY3YbddOMeta?.path ?? "/pl/data-check/:slug()",
    meta: _91slug_93RLpY3YbddOMeta || {},
    alias: _91slug_93RLpY3YbddOMeta?.alias || [],
    redirect: _91slug_93RLpY3YbddOMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/data-check/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexAubJnXmWUPMeta?.name ?? "data-check___de",
    path: indexAubJnXmWUPMeta?.path ?? "/de/data-check",
    meta: indexAubJnXmWUPMeta || {},
    alias: indexAubJnXmWUPMeta?.alias || [],
    redirect: indexAubJnXmWUPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/data-check/index.vue").then(m => m.default || m)
  },
  {
    name: indexAubJnXmWUPMeta?.name ?? "data-check___en",
    path: indexAubJnXmWUPMeta?.path ?? "/en/data-check",
    meta: indexAubJnXmWUPMeta || {},
    alias: indexAubJnXmWUPMeta?.alias || [],
    redirect: indexAubJnXmWUPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/data-check/index.vue").then(m => m.default || m)
  },
  {
    name: indexAubJnXmWUPMeta?.name ?? "data-check___fr",
    path: indexAubJnXmWUPMeta?.path ?? "/fr/data-check",
    meta: indexAubJnXmWUPMeta || {},
    alias: indexAubJnXmWUPMeta?.alias || [],
    redirect: indexAubJnXmWUPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/data-check/index.vue").then(m => m.default || m)
  },
  {
    name: indexAubJnXmWUPMeta?.name ?? "data-check___cs",
    path: indexAubJnXmWUPMeta?.path ?? "/cs/data-check",
    meta: indexAubJnXmWUPMeta || {},
    alias: indexAubJnXmWUPMeta?.alias || [],
    redirect: indexAubJnXmWUPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/data-check/index.vue").then(m => m.default || m)
  },
  {
    name: indexAubJnXmWUPMeta?.name ?? "data-check___it",
    path: indexAubJnXmWUPMeta?.path ?? "/it/data-check",
    meta: indexAubJnXmWUPMeta || {},
    alias: indexAubJnXmWUPMeta?.alias || [],
    redirect: indexAubJnXmWUPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/data-check/index.vue").then(m => m.default || m)
  },
  {
    name: indexAubJnXmWUPMeta?.name ?? "data-check___pl",
    path: indexAubJnXmWUPMeta?.path ?? "/pl/data-check",
    meta: indexAubJnXmWUPMeta || {},
    alias: indexAubJnXmWUPMeta?.alias || [],
    redirect: indexAubJnXmWUPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/data-check/index.vue").then(m => m.default || m)
  },
  {
    name: deliveryyxkY4RZzldMeta?.name ?? "delivery___de",
    path: deliveryyxkY4RZzldMeta?.path ?? "/de/delivery",
    meta: deliveryyxkY4RZzldMeta || {},
    alias: deliveryyxkY4RZzldMeta?.alias || [],
    redirect: deliveryyxkY4RZzldMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: deliveryyxkY4RZzldMeta?.name ?? "delivery___en",
    path: deliveryyxkY4RZzldMeta?.path ?? "/en/delivery",
    meta: deliveryyxkY4RZzldMeta || {},
    alias: deliveryyxkY4RZzldMeta?.alias || [],
    redirect: deliveryyxkY4RZzldMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: deliveryyxkY4RZzldMeta?.name ?? "delivery___fr",
    path: deliveryyxkY4RZzldMeta?.path ?? "/fr/delivery",
    meta: deliveryyxkY4RZzldMeta || {},
    alias: deliveryyxkY4RZzldMeta?.alias || [],
    redirect: deliveryyxkY4RZzldMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: deliveryyxkY4RZzldMeta?.name ?? "delivery___cs",
    path: deliveryyxkY4RZzldMeta?.path ?? "/cs/delivery",
    meta: deliveryyxkY4RZzldMeta || {},
    alias: deliveryyxkY4RZzldMeta?.alias || [],
    redirect: deliveryyxkY4RZzldMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: deliveryyxkY4RZzldMeta?.name ?? "delivery___it",
    path: deliveryyxkY4RZzldMeta?.path ?? "/it/delivery",
    meta: deliveryyxkY4RZzldMeta || {},
    alias: deliveryyxkY4RZzldMeta?.alias || [],
    redirect: deliveryyxkY4RZzldMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: deliveryyxkY4RZzldMeta?.name ?? "delivery___pl",
    path: deliveryyxkY4RZzldMeta?.path ?? "/pl/delivery",
    meta: deliveryyxkY4RZzldMeta || {},
    alias: deliveryyxkY4RZzldMeta?.alias || [],
    redirect: deliveryyxkY4RZzldMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/delivery.vue").then(m => m.default || m)
  },
  {
    name: dictionaryusXI9VIn8wMeta?.name ?? "dictionary___de",
    path: dictionaryusXI9VIn8wMeta?.path ?? "/de/dictionary",
    meta: dictionaryusXI9VIn8wMeta || {},
    alias: dictionaryusXI9VIn8wMeta?.alias || [],
    redirect: dictionaryusXI9VIn8wMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/dictionary.vue").then(m => m.default || m)
  },
  {
    name: digiboxyPJTG21D8LMeta?.name ?? "digibox___de",
    path: digiboxyPJTG21D8LMeta?.path ?? "/de/digibox",
    meta: digiboxyPJTG21D8LMeta || {},
    alias: digiboxyPJTG21D8LMeta?.alias || [],
    redirect: digiboxyPJTG21D8LMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/digibox.vue").then(m => m.default || m)
  },
  {
    name: digiboxyPJTG21D8LMeta?.name ?? "digibox___en",
    path: digiboxyPJTG21D8LMeta?.path ?? "/en/digibox",
    meta: digiboxyPJTG21D8LMeta || {},
    alias: digiboxyPJTG21D8LMeta?.alias || [],
    redirect: digiboxyPJTG21D8LMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/digibox.vue").then(m => m.default || m)
  },
  {
    name: digiboxyPJTG21D8LMeta?.name ?? "digibox___fr",
    path: digiboxyPJTG21D8LMeta?.path ?? "/fr/digibox",
    meta: digiboxyPJTG21D8LMeta || {},
    alias: digiboxyPJTG21D8LMeta?.alias || [],
    redirect: digiboxyPJTG21D8LMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/digibox.vue").then(m => m.default || m)
  },
  {
    name: digiboxyPJTG21D8LMeta?.name ?? "digibox___cs",
    path: digiboxyPJTG21D8LMeta?.path ?? "/cs/digibox",
    meta: digiboxyPJTG21D8LMeta || {},
    alias: digiboxyPJTG21D8LMeta?.alias || [],
    redirect: digiboxyPJTG21D8LMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/digibox.vue").then(m => m.default || m)
  },
  {
    name: digiboxyPJTG21D8LMeta?.name ?? "digibox___it",
    path: digiboxyPJTG21D8LMeta?.path ?? "/it/digibox",
    meta: digiboxyPJTG21D8LMeta || {},
    alias: digiboxyPJTG21D8LMeta?.alias || [],
    redirect: digiboxyPJTG21D8LMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/digibox.vue").then(m => m.default || m)
  },
  {
    name: digiboxyPJTG21D8LMeta?.name ?? "digibox___pl",
    path: digiboxyPJTG21D8LMeta?.path ?? "/pl/digibox",
    meta: digiboxyPJTG21D8LMeta || {},
    alias: digiboxyPJTG21D8LMeta?.alias || [],
    redirect: digiboxyPJTG21D8LMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/digibox.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WEsvOGNwk4Meta?.name ?? "faq-slug___de",
    path: _91slug_93WEsvOGNwk4Meta?.path ?? "/de/faq/:slug()",
    meta: _91slug_93WEsvOGNwk4Meta || {},
    alias: _91slug_93WEsvOGNwk4Meta?.alias || [],
    redirect: _91slug_93WEsvOGNwk4Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WEsvOGNwk4Meta?.name ?? "faq-slug___en",
    path: _91slug_93WEsvOGNwk4Meta?.path ?? "/en/faq/:slug()",
    meta: _91slug_93WEsvOGNwk4Meta || {},
    alias: _91slug_93WEsvOGNwk4Meta?.alias || [],
    redirect: _91slug_93WEsvOGNwk4Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WEsvOGNwk4Meta?.name ?? "faq-slug___fr",
    path: _91slug_93WEsvOGNwk4Meta?.path ?? "/fr/faq/:slug()",
    meta: _91slug_93WEsvOGNwk4Meta || {},
    alias: _91slug_93WEsvOGNwk4Meta?.alias || [],
    redirect: _91slug_93WEsvOGNwk4Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WEsvOGNwk4Meta?.name ?? "faq-slug___cs",
    path: _91slug_93WEsvOGNwk4Meta?.path ?? "/cs/faq/:slug()",
    meta: _91slug_93WEsvOGNwk4Meta || {},
    alias: _91slug_93WEsvOGNwk4Meta?.alias || [],
    redirect: _91slug_93WEsvOGNwk4Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WEsvOGNwk4Meta?.name ?? "faq-slug___it",
    path: _91slug_93WEsvOGNwk4Meta?.path ?? "/it/faq/:slug()",
    meta: _91slug_93WEsvOGNwk4Meta || {},
    alias: _91slug_93WEsvOGNwk4Meta?.alias || [],
    redirect: _91slug_93WEsvOGNwk4Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WEsvOGNwk4Meta?.name ?? "faq-slug___pl",
    path: _91slug_93WEsvOGNwk4Meta?.path ?? "/pl/faq/:slug()",
    meta: _91slug_93WEsvOGNwk4Meta || {},
    alias: _91slug_93WEsvOGNwk4Meta?.alias || [],
    redirect: _91slug_93WEsvOGNwk4Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/faq/[slug].vue").then(m => m.default || m)
  },
  {
    name: index5hW08pKmg9Meta?.name ?? "faq___de",
    path: index5hW08pKmg9Meta?.path ?? "/de/faq",
    meta: index5hW08pKmg9Meta || {},
    alias: index5hW08pKmg9Meta?.alias || [],
    redirect: index5hW08pKmg9Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: index5hW08pKmg9Meta?.name ?? "faq___en",
    path: index5hW08pKmg9Meta?.path ?? "/en/faq",
    meta: index5hW08pKmg9Meta || {},
    alias: index5hW08pKmg9Meta?.alias || [],
    redirect: index5hW08pKmg9Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: index5hW08pKmg9Meta?.name ?? "faq___fr",
    path: index5hW08pKmg9Meta?.path ?? "/fr/faq",
    meta: index5hW08pKmg9Meta || {},
    alias: index5hW08pKmg9Meta?.alias || [],
    redirect: index5hW08pKmg9Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: index5hW08pKmg9Meta?.name ?? "faq___cs",
    path: index5hW08pKmg9Meta?.path ?? "/cs/faq",
    meta: index5hW08pKmg9Meta || {},
    alias: index5hW08pKmg9Meta?.alias || [],
    redirect: index5hW08pKmg9Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: index5hW08pKmg9Meta?.name ?? "faq___it",
    path: index5hW08pKmg9Meta?.path ?? "/it/faq",
    meta: index5hW08pKmg9Meta || {},
    alias: index5hW08pKmg9Meta?.alias || [],
    redirect: index5hW08pKmg9Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: index5hW08pKmg9Meta?.name ?? "faq___pl",
    path: index5hW08pKmg9Meta?.path ?? "/pl/faq",
    meta: index5hW08pKmg9Meta || {},
    alias: index5hW08pKmg9Meta?.alias || [],
    redirect: index5hW08pKmg9Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: folding_45boxes_45formQzFineVXRWMeta?.name ?? "folding-boxes-form___de",
    path: folding_45boxes_45formQzFineVXRWMeta?.path ?? "/de/folding-boxes-form",
    meta: folding_45boxes_45formQzFineVXRWMeta || {},
    alias: folding_45boxes_45formQzFineVXRWMeta?.alias || [],
    redirect: folding_45boxes_45formQzFineVXRWMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/folding-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: folding_45boxes_45formQzFineVXRWMeta?.name ?? "folding-boxes-form___en",
    path: folding_45boxes_45formQzFineVXRWMeta?.path ?? "/en/folding-boxes-form",
    meta: folding_45boxes_45formQzFineVXRWMeta || {},
    alias: folding_45boxes_45formQzFineVXRWMeta?.alias || [],
    redirect: folding_45boxes_45formQzFineVXRWMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/folding-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: folding_45boxes_45formQzFineVXRWMeta?.name ?? "folding-boxes-form___fr",
    path: folding_45boxes_45formQzFineVXRWMeta?.path ?? "/fr/folding-boxes-form",
    meta: folding_45boxes_45formQzFineVXRWMeta || {},
    alias: folding_45boxes_45formQzFineVXRWMeta?.alias || [],
    redirect: folding_45boxes_45formQzFineVXRWMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/folding-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: folding_45boxes_45formQzFineVXRWMeta?.name ?? "folding-boxes-form___cs",
    path: folding_45boxes_45formQzFineVXRWMeta?.path ?? "/cs/folding-boxes-form",
    meta: folding_45boxes_45formQzFineVXRWMeta || {},
    alias: folding_45boxes_45formQzFineVXRWMeta?.alias || [],
    redirect: folding_45boxes_45formQzFineVXRWMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/folding-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: folding_45boxes_45formQzFineVXRWMeta?.name ?? "folding-boxes-form___it",
    path: folding_45boxes_45formQzFineVXRWMeta?.path ?? "/it/folding-boxes-form",
    meta: folding_45boxes_45formQzFineVXRWMeta || {},
    alias: folding_45boxes_45formQzFineVXRWMeta?.alias || [],
    redirect: folding_45boxes_45formQzFineVXRWMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/folding-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: folding_45boxes_45formQzFineVXRWMeta?.name ?? "folding-boxes-form___pl",
    path: folding_45boxes_45formQzFineVXRWMeta?.path ?? "/pl/folding-boxes-form",
    meta: folding_45boxes_45formQzFineVXRWMeta || {},
    alias: folding_45boxes_45formQzFineVXRWMeta?.alias || [],
    redirect: folding_45boxes_45formQzFineVXRWMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/folding-boxes-form.vue").then(m => m.default || m)
  },
  {
    name: food_45labels_45informationsBpJhdsdahoMeta?.name ?? "food-labels-informations___de",
    path: food_45labels_45informationsBpJhdsdahoMeta?.path ?? "/de/food-labels-informations",
    meta: food_45labels_45informationsBpJhdsdahoMeta || {},
    alias: food_45labels_45informationsBpJhdsdahoMeta?.alias || [],
    redirect: food_45labels_45informationsBpJhdsdahoMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/food-labels-informations.vue").then(m => m.default || m)
  },
  {
    name: healthzmq4s5YxpPOMeta?.name ?? "healthz___de",
    path: healthzmq4s5YxpPOMeta?.path ?? "/de/healthz",
    meta: healthzmq4s5YxpPOMeta || {},
    alias: healthzmq4s5YxpPOMeta?.alias || [],
    redirect: healthzmq4s5YxpPOMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/healthz.vue").then(m => m.default || m)
  },
  {
    name: healthzmq4s5YxpPOMeta?.name ?? "healthz___en",
    path: healthzmq4s5YxpPOMeta?.path ?? "/en/healthz",
    meta: healthzmq4s5YxpPOMeta || {},
    alias: healthzmq4s5YxpPOMeta?.alias || [],
    redirect: healthzmq4s5YxpPOMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/healthz.vue").then(m => m.default || m)
  },
  {
    name: healthzmq4s5YxpPOMeta?.name ?? "healthz___fr",
    path: healthzmq4s5YxpPOMeta?.path ?? "/fr/healthz",
    meta: healthzmq4s5YxpPOMeta || {},
    alias: healthzmq4s5YxpPOMeta?.alias || [],
    redirect: healthzmq4s5YxpPOMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/healthz.vue").then(m => m.default || m)
  },
  {
    name: healthzmq4s5YxpPOMeta?.name ?? "healthz___cs",
    path: healthzmq4s5YxpPOMeta?.path ?? "/cs/healthz",
    meta: healthzmq4s5YxpPOMeta || {},
    alias: healthzmq4s5YxpPOMeta?.alias || [],
    redirect: healthzmq4s5YxpPOMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/healthz.vue").then(m => m.default || m)
  },
  {
    name: healthzmq4s5YxpPOMeta?.name ?? "healthz___it",
    path: healthzmq4s5YxpPOMeta?.path ?? "/it/healthz",
    meta: healthzmq4s5YxpPOMeta || {},
    alias: healthzmq4s5YxpPOMeta?.alias || [],
    redirect: healthzmq4s5YxpPOMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/healthz.vue").then(m => m.default || m)
  },
  {
    name: healthzmq4s5YxpPOMeta?.name ?? "healthz___pl",
    path: healthzmq4s5YxpPOMeta?.path ?? "/pl/healthz",
    meta: healthzmq4s5YxpPOMeta || {},
    alias: healthzmq4s5YxpPOMeta?.alias || [],
    redirect: healthzmq4s5YxpPOMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/healthz.vue").then(m => m.default || m)
  },
  {
    name: impressumvxN7EZ7dlsMeta?.name ?? "impressum___de",
    path: impressumvxN7EZ7dlsMeta?.path ?? "/de/impressum",
    meta: impressumvxN7EZ7dlsMeta || {},
    alias: impressumvxN7EZ7dlsMeta?.alias || [],
    redirect: impressumvxN7EZ7dlsMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressumvxN7EZ7dlsMeta?.name ?? "impressum___en",
    path: impressumvxN7EZ7dlsMeta?.path ?? "/en/impressum",
    meta: impressumvxN7EZ7dlsMeta || {},
    alias: impressumvxN7EZ7dlsMeta?.alias || [],
    redirect: impressumvxN7EZ7dlsMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressumvxN7EZ7dlsMeta?.name ?? "impressum___fr",
    path: impressumvxN7EZ7dlsMeta?.path ?? "/fr/impressum",
    meta: impressumvxN7EZ7dlsMeta || {},
    alias: impressumvxN7EZ7dlsMeta?.alias || [],
    redirect: impressumvxN7EZ7dlsMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressumvxN7EZ7dlsMeta?.name ?? "impressum___cs",
    path: impressumvxN7EZ7dlsMeta?.path ?? "/cs/impressum",
    meta: impressumvxN7EZ7dlsMeta || {},
    alias: impressumvxN7EZ7dlsMeta?.alias || [],
    redirect: impressumvxN7EZ7dlsMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressumvxN7EZ7dlsMeta?.name ?? "impressum___it",
    path: impressumvxN7EZ7dlsMeta?.path ?? "/it/impressum",
    meta: impressumvxN7EZ7dlsMeta || {},
    alias: impressumvxN7EZ7dlsMeta?.alias || [],
    redirect: impressumvxN7EZ7dlsMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: impressumvxN7EZ7dlsMeta?.name ?? "impressum___pl",
    path: impressumvxN7EZ7dlsMeta?.path ?? "/pl/impressum",
    meta: impressumvxN7EZ7dlsMeta || {},
    alias: impressumvxN7EZ7dlsMeta?.alias || [],
    redirect: impressumvxN7EZ7dlsMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexTL50S0xoIpMeta?.name ?? "index___de",
    path: indexTL50S0xoIpMeta?.path ?? "/de",
    meta: indexTL50S0xoIpMeta || {},
    alias: indexTL50S0xoIpMeta?.alias || [],
    redirect: indexTL50S0xoIpMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexTL50S0xoIpMeta?.name ?? "index___en",
    path: indexTL50S0xoIpMeta?.path ?? "/en",
    meta: indexTL50S0xoIpMeta || {},
    alias: indexTL50S0xoIpMeta?.alias || [],
    redirect: indexTL50S0xoIpMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexTL50S0xoIpMeta?.name ?? "index___fr",
    path: indexTL50S0xoIpMeta?.path ?? "/fr",
    meta: indexTL50S0xoIpMeta || {},
    alias: indexTL50S0xoIpMeta?.alias || [],
    redirect: indexTL50S0xoIpMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexTL50S0xoIpMeta?.name ?? "index___cs",
    path: indexTL50S0xoIpMeta?.path ?? "/cs",
    meta: indexTL50S0xoIpMeta || {},
    alias: indexTL50S0xoIpMeta?.alias || [],
    redirect: indexTL50S0xoIpMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexTL50S0xoIpMeta?.name ?? "index___it",
    path: indexTL50S0xoIpMeta?.path ?? "/it",
    meta: indexTL50S0xoIpMeta || {},
    alias: indexTL50S0xoIpMeta?.alias || [],
    redirect: indexTL50S0xoIpMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexTL50S0xoIpMeta?.name ?? "index___pl",
    path: indexTL50S0xoIpMeta?.path ?? "/pl",
    meta: indexTL50S0xoIpMeta || {},
    alias: indexTL50S0xoIpMeta?.alias || [],
    redirect: indexTL50S0xoIpMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bR1a6iu5ugMeta?.name ?? "jobs-slug___de",
    path: _91slug_93bR1a6iu5ugMeta?.path ?? "/de/jobs/:slug()",
    meta: _91slug_93bR1a6iu5ugMeta || {},
    alias: _91slug_93bR1a6iu5ugMeta?.alias || [],
    redirect: _91slug_93bR1a6iu5ugMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bR1a6iu5ugMeta?.name ?? "jobs-slug___en",
    path: _91slug_93bR1a6iu5ugMeta?.path ?? "/en/jobs/:slug()",
    meta: _91slug_93bR1a6iu5ugMeta || {},
    alias: _91slug_93bR1a6iu5ugMeta?.alias || [],
    redirect: _91slug_93bR1a6iu5ugMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bR1a6iu5ugMeta?.name ?? "jobs-slug___fr",
    path: _91slug_93bR1a6iu5ugMeta?.path ?? "/fr/jobs/:slug()",
    meta: _91slug_93bR1a6iu5ugMeta || {},
    alias: _91slug_93bR1a6iu5ugMeta?.alias || [],
    redirect: _91slug_93bR1a6iu5ugMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bR1a6iu5ugMeta?.name ?? "jobs-slug___cs",
    path: _91slug_93bR1a6iu5ugMeta?.path ?? "/cs/jobs/:slug()",
    meta: _91slug_93bR1a6iu5ugMeta || {},
    alias: _91slug_93bR1a6iu5ugMeta?.alias || [],
    redirect: _91slug_93bR1a6iu5ugMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bR1a6iu5ugMeta?.name ?? "jobs-slug___it",
    path: _91slug_93bR1a6iu5ugMeta?.path ?? "/it/jobs/:slug()",
    meta: _91slug_93bR1a6iu5ugMeta || {},
    alias: _91slug_93bR1a6iu5ugMeta?.alias || [],
    redirect: _91slug_93bR1a6iu5ugMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bR1a6iu5ugMeta?.name ?? "jobs-slug___pl",
    path: _91slug_93bR1a6iu5ugMeta?.path ?? "/pl/jobs/:slug()",
    meta: _91slug_93bR1a6iu5ugMeta || {},
    alias: _91slug_93bR1a6iu5ugMeta?.alias || [],
    redirect: _91slug_93bR1a6iu5ugMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexlTzws98g0aMeta?.name ?? "jobs___de",
    path: indexlTzws98g0aMeta?.path ?? "/de/jobs",
    meta: indexlTzws98g0aMeta || {},
    alias: indexlTzws98g0aMeta?.alias || [],
    redirect: indexlTzws98g0aMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexlTzws98g0aMeta?.name ?? "jobs___en",
    path: indexlTzws98g0aMeta?.path ?? "/en/jobs",
    meta: indexlTzws98g0aMeta || {},
    alias: indexlTzws98g0aMeta?.alias || [],
    redirect: indexlTzws98g0aMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexlTzws98g0aMeta?.name ?? "jobs___fr",
    path: indexlTzws98g0aMeta?.path ?? "/fr/jobs",
    meta: indexlTzws98g0aMeta || {},
    alias: indexlTzws98g0aMeta?.alias || [],
    redirect: indexlTzws98g0aMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexlTzws98g0aMeta?.name ?? "jobs___cs",
    path: indexlTzws98g0aMeta?.path ?? "/cs/jobs",
    meta: indexlTzws98g0aMeta || {},
    alias: indexlTzws98g0aMeta?.alias || [],
    redirect: indexlTzws98g0aMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexlTzws98g0aMeta?.name ?? "jobs___it",
    path: indexlTzws98g0aMeta?.path ?? "/it/jobs",
    meta: indexlTzws98g0aMeta || {},
    alias: indexlTzws98g0aMeta?.alias || [],
    redirect: indexlTzws98g0aMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexlTzws98g0aMeta?.name ?? "jobs___pl",
    path: indexlTzws98g0aMeta?.path ?? "/pl/jobs",
    meta: indexlTzws98g0aMeta || {},
    alias: indexlTzws98g0aMeta?.alias || [],
    redirect: indexlTzws98g0aMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: laminate_45tubes_45formUKwcMmr0EGMeta?.name ?? "laminate-tubes-form___de",
    path: laminate_45tubes_45formUKwcMmr0EGMeta?.path ?? "/de/laminate-tubes-form",
    meta: laminate_45tubes_45formUKwcMmr0EGMeta || {},
    alias: laminate_45tubes_45formUKwcMmr0EGMeta?.alias || [],
    redirect: laminate_45tubes_45formUKwcMmr0EGMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/laminate-tubes-form.vue").then(m => m.default || m)
  },
  {
    name: laminate_45tubes_45formUKwcMmr0EGMeta?.name ?? "laminate-tubes-form___en",
    path: laminate_45tubes_45formUKwcMmr0EGMeta?.path ?? "/en/laminate-tubes-form",
    meta: laminate_45tubes_45formUKwcMmr0EGMeta || {},
    alias: laminate_45tubes_45formUKwcMmr0EGMeta?.alias || [],
    redirect: laminate_45tubes_45formUKwcMmr0EGMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/laminate-tubes-form.vue").then(m => m.default || m)
  },
  {
    name: laminate_45tubes_45formUKwcMmr0EGMeta?.name ?? "laminate-tubes-form___fr",
    path: laminate_45tubes_45formUKwcMmr0EGMeta?.path ?? "/fr/laminate-tubes-form",
    meta: laminate_45tubes_45formUKwcMmr0EGMeta || {},
    alias: laminate_45tubes_45formUKwcMmr0EGMeta?.alias || [],
    redirect: laminate_45tubes_45formUKwcMmr0EGMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/laminate-tubes-form.vue").then(m => m.default || m)
  },
  {
    name: laminate_45tubes_45formUKwcMmr0EGMeta?.name ?? "laminate-tubes-form___cs",
    path: laminate_45tubes_45formUKwcMmr0EGMeta?.path ?? "/cs/laminate-tubes-form",
    meta: laminate_45tubes_45formUKwcMmr0EGMeta || {},
    alias: laminate_45tubes_45formUKwcMmr0EGMeta?.alias || [],
    redirect: laminate_45tubes_45formUKwcMmr0EGMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/laminate-tubes-form.vue").then(m => m.default || m)
  },
  {
    name: laminate_45tubes_45formUKwcMmr0EGMeta?.name ?? "laminate-tubes-form___it",
    path: laminate_45tubes_45formUKwcMmr0EGMeta?.path ?? "/it/laminate-tubes-form",
    meta: laminate_45tubes_45formUKwcMmr0EGMeta || {},
    alias: laminate_45tubes_45formUKwcMmr0EGMeta?.alias || [],
    redirect: laminate_45tubes_45formUKwcMmr0EGMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/laminate-tubes-form.vue").then(m => m.default || m)
  },
  {
    name: laminate_45tubes_45formUKwcMmr0EGMeta?.name ?? "laminate-tubes-form___pl",
    path: laminate_45tubes_45formUKwcMmr0EGMeta?.path ?? "/pl/laminate-tubes-form",
    meta: laminate_45tubes_45formUKwcMmr0EGMeta || {},
    alias: laminate_45tubes_45formUKwcMmr0EGMeta?.alias || [],
    redirect: laminate_45tubes_45formUKwcMmr0EGMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/laminate-tubes-form.vue").then(m => m.default || m)
  },
  {
    name: loginleSlZWr5SQMeta?.name ?? "login___de",
    path: loginleSlZWr5SQMeta?.path ?? "/de/login",
    meta: loginleSlZWr5SQMeta || {},
    alias: loginleSlZWr5SQMeta?.alias || [],
    redirect: loginleSlZWr5SQMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginleSlZWr5SQMeta?.name ?? "login___en",
    path: loginleSlZWr5SQMeta?.path ?? "/en/login",
    meta: loginleSlZWr5SQMeta || {},
    alias: loginleSlZWr5SQMeta?.alias || [],
    redirect: loginleSlZWr5SQMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginleSlZWr5SQMeta?.name ?? "login___fr",
    path: loginleSlZWr5SQMeta?.path ?? "/fr/login",
    meta: loginleSlZWr5SQMeta || {},
    alias: loginleSlZWr5SQMeta?.alias || [],
    redirect: loginleSlZWr5SQMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginleSlZWr5SQMeta?.name ?? "login___cs",
    path: loginleSlZWr5SQMeta?.path ?? "/cs/login",
    meta: loginleSlZWr5SQMeta || {},
    alias: loginleSlZWr5SQMeta?.alias || [],
    redirect: loginleSlZWr5SQMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginleSlZWr5SQMeta?.name ?? "login___it",
    path: loginleSlZWr5SQMeta?.path ?? "/it/login",
    meta: loginleSlZWr5SQMeta || {},
    alias: loginleSlZWr5SQMeta?.alias || [],
    redirect: loginleSlZWr5SQMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginleSlZWr5SQMeta?.name ?? "login___pl",
    path: loginleSlZWr5SQMeta?.path ?? "/pl/login",
    meta: loginleSlZWr5SQMeta || {},
    alias: loginleSlZWr5SQMeta?.alias || [],
    redirect: loginleSlZWr5SQMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/login.vue").then(m => m.default || m)
  },
  {
    name: machinesVeXazlSxTyMeta?.name ?? "machines___de",
    path: machinesVeXazlSxTyMeta?.path ?? "/de/machines",
    meta: machinesVeXazlSxTyMeta || {},
    alias: machinesVeXazlSxTyMeta?.alias || [],
    redirect: machinesVeXazlSxTyMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/machines.vue").then(m => m.default || m)
  },
  {
    name: machinesVeXazlSxTyMeta?.name ?? "machines___en",
    path: machinesVeXazlSxTyMeta?.path ?? "/en/machines",
    meta: machinesVeXazlSxTyMeta || {},
    alias: machinesVeXazlSxTyMeta?.alias || [],
    redirect: machinesVeXazlSxTyMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/machines.vue").then(m => m.default || m)
  },
  {
    name: machinesVeXazlSxTyMeta?.name ?? "machines___fr",
    path: machinesVeXazlSxTyMeta?.path ?? "/fr/machines",
    meta: machinesVeXazlSxTyMeta || {},
    alias: machinesVeXazlSxTyMeta?.alias || [],
    redirect: machinesVeXazlSxTyMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/machines.vue").then(m => m.default || m)
  },
  {
    name: machinesVeXazlSxTyMeta?.name ?? "machines___cs",
    path: machinesVeXazlSxTyMeta?.path ?? "/cs/machines",
    meta: machinesVeXazlSxTyMeta || {},
    alias: machinesVeXazlSxTyMeta?.alias || [],
    redirect: machinesVeXazlSxTyMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/machines.vue").then(m => m.default || m)
  },
  {
    name: machinesVeXazlSxTyMeta?.name ?? "machines___it",
    path: machinesVeXazlSxTyMeta?.path ?? "/it/machines",
    meta: machinesVeXazlSxTyMeta || {},
    alias: machinesVeXazlSxTyMeta?.alias || [],
    redirect: machinesVeXazlSxTyMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/machines.vue").then(m => m.default || m)
  },
  {
    name: machinesVeXazlSxTyMeta?.name ?? "machines___pl",
    path: machinesVeXazlSxTyMeta?.path ?? "/pl/machines",
    meta: machinesVeXazlSxTyMeta || {},
    alias: machinesVeXazlSxTyMeta?.alias || [],
    redirect: machinesVeXazlSxTyMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/machines.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fzgGhaud8PMeta?.name ?? "magazine-slug___de",
    path: _91slug_93fzgGhaud8PMeta?.path ?? "/de/magazine/:slug()",
    meta: _91slug_93fzgGhaud8PMeta || {},
    alias: _91slug_93fzgGhaud8PMeta?.alias || [],
    redirect: _91slug_93fzgGhaud8PMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/magazine/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fzgGhaud8PMeta?.name ?? "magazine-slug___en",
    path: _91slug_93fzgGhaud8PMeta?.path ?? "/en/magazine/:slug()",
    meta: _91slug_93fzgGhaud8PMeta || {},
    alias: _91slug_93fzgGhaud8PMeta?.alias || [],
    redirect: _91slug_93fzgGhaud8PMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/magazine/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fzgGhaud8PMeta?.name ?? "magazine-slug___fr",
    path: _91slug_93fzgGhaud8PMeta?.path ?? "/fr/magazine/:slug()",
    meta: _91slug_93fzgGhaud8PMeta || {},
    alias: _91slug_93fzgGhaud8PMeta?.alias || [],
    redirect: _91slug_93fzgGhaud8PMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/magazine/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fzgGhaud8PMeta?.name ?? "magazine-slug___cs",
    path: _91slug_93fzgGhaud8PMeta?.path ?? "/cs/magazine/:slug()",
    meta: _91slug_93fzgGhaud8PMeta || {},
    alias: _91slug_93fzgGhaud8PMeta?.alias || [],
    redirect: _91slug_93fzgGhaud8PMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/magazine/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fzgGhaud8PMeta?.name ?? "magazine-slug___it",
    path: _91slug_93fzgGhaud8PMeta?.path ?? "/it/magazine/:slug()",
    meta: _91slug_93fzgGhaud8PMeta || {},
    alias: _91slug_93fzgGhaud8PMeta?.alias || [],
    redirect: _91slug_93fzgGhaud8PMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/magazine/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fzgGhaud8PMeta?.name ?? "magazine-slug___pl",
    path: _91slug_93fzgGhaud8PMeta?.path ?? "/pl/magazine/:slug()",
    meta: _91slug_93fzgGhaud8PMeta || {},
    alias: _91slug_93fzgGhaud8PMeta?.alias || [],
    redirect: _91slug_93fzgGhaud8PMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/magazine/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexIfH8CgOMpuMeta?.name ?? "magazine___de",
    path: indexIfH8CgOMpuMeta?.path ?? "/de/magazine",
    meta: indexIfH8CgOMpuMeta || {},
    alias: indexIfH8CgOMpuMeta?.alias || [],
    redirect: indexIfH8CgOMpuMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: indexIfH8CgOMpuMeta?.name ?? "magazine___en",
    path: indexIfH8CgOMpuMeta?.path ?? "/en/magazine",
    meta: indexIfH8CgOMpuMeta || {},
    alias: indexIfH8CgOMpuMeta?.alias || [],
    redirect: indexIfH8CgOMpuMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: indexIfH8CgOMpuMeta?.name ?? "magazine___fr",
    path: indexIfH8CgOMpuMeta?.path ?? "/fr/magazine",
    meta: indexIfH8CgOMpuMeta || {},
    alias: indexIfH8CgOMpuMeta?.alias || [],
    redirect: indexIfH8CgOMpuMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: indexIfH8CgOMpuMeta?.name ?? "magazine___cs",
    path: indexIfH8CgOMpuMeta?.path ?? "/cs/magazine",
    meta: indexIfH8CgOMpuMeta || {},
    alias: indexIfH8CgOMpuMeta?.alias || [],
    redirect: indexIfH8CgOMpuMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: indexIfH8CgOMpuMeta?.name ?? "magazine___it",
    path: indexIfH8CgOMpuMeta?.path ?? "/it/magazine",
    meta: indexIfH8CgOMpuMeta || {},
    alias: indexIfH8CgOMpuMeta?.alias || [],
    redirect: indexIfH8CgOMpuMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: indexIfH8CgOMpuMeta?.name ?? "magazine___pl",
    path: indexIfH8CgOMpuMeta?.path ?? "/pl/magazine",
    meta: indexIfH8CgOMpuMeta || {},
    alias: indexIfH8CgOMpuMeta?.alias || [],
    redirect: indexIfH8CgOMpuMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: major_45customers_45contactQ37ZPONBD8Meta?.name ?? "major-customers-contact___de",
    path: major_45customers_45contactQ37ZPONBD8Meta?.path ?? "/de/major-customers-contact",
    meta: major_45customers_45contactQ37ZPONBD8Meta || {},
    alias: major_45customers_45contactQ37ZPONBD8Meta?.alias || [],
    redirect: major_45customers_45contactQ37ZPONBD8Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/major-customers-contact.vue").then(m => m.default || m)
  },
  {
    name: major_45customers_45contactQ37ZPONBD8Meta?.name ?? "major-customers-contact___en",
    path: major_45customers_45contactQ37ZPONBD8Meta?.path ?? "/en/major-customers-contact",
    meta: major_45customers_45contactQ37ZPONBD8Meta || {},
    alias: major_45customers_45contactQ37ZPONBD8Meta?.alias || [],
    redirect: major_45customers_45contactQ37ZPONBD8Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/major-customers-contact.vue").then(m => m.default || m)
  },
  {
    name: major_45customers_45contactQ37ZPONBD8Meta?.name ?? "major-customers-contact___fr",
    path: major_45customers_45contactQ37ZPONBD8Meta?.path ?? "/fr/major-customers-contact",
    meta: major_45customers_45contactQ37ZPONBD8Meta || {},
    alias: major_45customers_45contactQ37ZPONBD8Meta?.alias || [],
    redirect: major_45customers_45contactQ37ZPONBD8Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/major-customers-contact.vue").then(m => m.default || m)
  },
  {
    name: major_45customers_45contactQ37ZPONBD8Meta?.name ?? "major-customers-contact___cs",
    path: major_45customers_45contactQ37ZPONBD8Meta?.path ?? "/cs/major-customers-contact",
    meta: major_45customers_45contactQ37ZPONBD8Meta || {},
    alias: major_45customers_45contactQ37ZPONBD8Meta?.alias || [],
    redirect: major_45customers_45contactQ37ZPONBD8Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/major-customers-contact.vue").then(m => m.default || m)
  },
  {
    name: major_45customers_45contactQ37ZPONBD8Meta?.name ?? "major-customers-contact___it",
    path: major_45customers_45contactQ37ZPONBD8Meta?.path ?? "/it/major-customers-contact",
    meta: major_45customers_45contactQ37ZPONBD8Meta || {},
    alias: major_45customers_45contactQ37ZPONBD8Meta?.alias || [],
    redirect: major_45customers_45contactQ37ZPONBD8Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/major-customers-contact.vue").then(m => m.default || m)
  },
  {
    name: major_45customers_45contactQ37ZPONBD8Meta?.name ?? "major-customers-contact___pl",
    path: major_45customers_45contactQ37ZPONBD8Meta?.path ?? "/pl/major-customers-contact",
    meta: major_45customers_45contactQ37ZPONBD8Meta || {},
    alias: major_45customers_45contactQ37ZPONBD8Meta?.alias || [],
    redirect: major_45customers_45contactQ37ZPONBD8Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/major-customers-contact.vue").then(m => m.default || m)
  },
  {
    name: major_45customerscpQ7Z3YH2sMeta?.name ?? "major-customers___de",
    path: major_45customerscpQ7Z3YH2sMeta?.path ?? "/de/major-customers",
    meta: major_45customerscpQ7Z3YH2sMeta || {},
    alias: major_45customerscpQ7Z3YH2sMeta?.alias || [],
    redirect: major_45customerscpQ7Z3YH2sMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/major-customers.vue").then(m => m.default || m)
  },
  {
    name: major_45customerscpQ7Z3YH2sMeta?.name ?? "major-customers___en",
    path: major_45customerscpQ7Z3YH2sMeta?.path ?? "/en/major-customers",
    meta: major_45customerscpQ7Z3YH2sMeta || {},
    alias: major_45customerscpQ7Z3YH2sMeta?.alias || [],
    redirect: major_45customerscpQ7Z3YH2sMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/major-customers.vue").then(m => m.default || m)
  },
  {
    name: major_45customerscpQ7Z3YH2sMeta?.name ?? "major-customers___fr",
    path: major_45customerscpQ7Z3YH2sMeta?.path ?? "/fr/major-customers",
    meta: major_45customerscpQ7Z3YH2sMeta || {},
    alias: major_45customerscpQ7Z3YH2sMeta?.alias || [],
    redirect: major_45customerscpQ7Z3YH2sMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/major-customers.vue").then(m => m.default || m)
  },
  {
    name: major_45customerscpQ7Z3YH2sMeta?.name ?? "major-customers___cs",
    path: major_45customerscpQ7Z3YH2sMeta?.path ?? "/cs/major-customers",
    meta: major_45customerscpQ7Z3YH2sMeta || {},
    alias: major_45customerscpQ7Z3YH2sMeta?.alias || [],
    redirect: major_45customerscpQ7Z3YH2sMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/major-customers.vue").then(m => m.default || m)
  },
  {
    name: major_45customerscpQ7Z3YH2sMeta?.name ?? "major-customers___it",
    path: major_45customerscpQ7Z3YH2sMeta?.path ?? "/it/major-customers",
    meta: major_45customerscpQ7Z3YH2sMeta || {},
    alias: major_45customerscpQ7Z3YH2sMeta?.alias || [],
    redirect: major_45customerscpQ7Z3YH2sMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/major-customers.vue").then(m => m.default || m)
  },
  {
    name: major_45customerscpQ7Z3YH2sMeta?.name ?? "major-customers___pl",
    path: major_45customerscpQ7Z3YH2sMeta?.path ?? "/pl/major-customers",
    meta: major_45customerscpQ7Z3YH2sMeta || {},
    alias: major_45customerscpQ7Z3YH2sMeta?.alias || [],
    redirect: major_45customerscpQ7Z3YH2sMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/major-customers.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dpFQ9Uko3kMeta?.name ?? "materials-slug___de",
    path: _91slug_93dpFQ9Uko3kMeta?.path ?? "/de/materials/:slug()",
    meta: _91slug_93dpFQ9Uko3kMeta || {},
    alias: _91slug_93dpFQ9Uko3kMeta?.alias || [],
    redirect: _91slug_93dpFQ9Uko3kMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/materials/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dpFQ9Uko3kMeta?.name ?? "materials-slug___en",
    path: _91slug_93dpFQ9Uko3kMeta?.path ?? "/en/materials/:slug()",
    meta: _91slug_93dpFQ9Uko3kMeta || {},
    alias: _91slug_93dpFQ9Uko3kMeta?.alias || [],
    redirect: _91slug_93dpFQ9Uko3kMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/materials/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dpFQ9Uko3kMeta?.name ?? "materials-slug___fr",
    path: _91slug_93dpFQ9Uko3kMeta?.path ?? "/fr/materials/:slug()",
    meta: _91slug_93dpFQ9Uko3kMeta || {},
    alias: _91slug_93dpFQ9Uko3kMeta?.alias || [],
    redirect: _91slug_93dpFQ9Uko3kMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/materials/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dpFQ9Uko3kMeta?.name ?? "materials-slug___cs",
    path: _91slug_93dpFQ9Uko3kMeta?.path ?? "/cs/materials/:slug()",
    meta: _91slug_93dpFQ9Uko3kMeta || {},
    alias: _91slug_93dpFQ9Uko3kMeta?.alias || [],
    redirect: _91slug_93dpFQ9Uko3kMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/materials/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dpFQ9Uko3kMeta?.name ?? "materials-slug___it",
    path: _91slug_93dpFQ9Uko3kMeta?.path ?? "/it/materials/:slug()",
    meta: _91slug_93dpFQ9Uko3kMeta || {},
    alias: _91slug_93dpFQ9Uko3kMeta?.alias || [],
    redirect: _91slug_93dpFQ9Uko3kMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/materials/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dpFQ9Uko3kMeta?.name ?? "materials-slug___pl",
    path: _91slug_93dpFQ9Uko3kMeta?.path ?? "/pl/materials/:slug()",
    meta: _91slug_93dpFQ9Uko3kMeta || {},
    alias: _91slug_93dpFQ9Uko3kMeta?.alias || [],
    redirect: _91slug_93dpFQ9Uko3kMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/materials/[slug].vue").then(m => m.default || m)
  },
  {
    name: index2YHJwghYq8Meta?.name ?? "materials___de",
    path: index2YHJwghYq8Meta?.path ?? "/de/materials",
    meta: index2YHJwghYq8Meta || {},
    alias: index2YHJwghYq8Meta?.alias || [],
    redirect: index2YHJwghYq8Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: index2YHJwghYq8Meta?.name ?? "materials___en",
    path: index2YHJwghYq8Meta?.path ?? "/en/materials",
    meta: index2YHJwghYq8Meta || {},
    alias: index2YHJwghYq8Meta?.alias || [],
    redirect: index2YHJwghYq8Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: index2YHJwghYq8Meta?.name ?? "materials___fr",
    path: index2YHJwghYq8Meta?.path ?? "/fr/materials",
    meta: index2YHJwghYq8Meta || {},
    alias: index2YHJwghYq8Meta?.alias || [],
    redirect: index2YHJwghYq8Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: index2YHJwghYq8Meta?.name ?? "materials___cs",
    path: index2YHJwghYq8Meta?.path ?? "/cs/materials",
    meta: index2YHJwghYq8Meta || {},
    alias: index2YHJwghYq8Meta?.alias || [],
    redirect: index2YHJwghYq8Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: index2YHJwghYq8Meta?.name ?? "materials___it",
    path: index2YHJwghYq8Meta?.path ?? "/it/materials",
    meta: index2YHJwghYq8Meta || {},
    alias: index2YHJwghYq8Meta?.alias || [],
    redirect: index2YHJwghYq8Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: index2YHJwghYq8Meta?.name ?? "materials___pl",
    path: index2YHJwghYq8Meta?.path ?? "/pl/materials",
    meta: index2YHJwghYq8Meta || {},
    alias: index2YHJwghYq8Meta?.alias || [],
    redirect: index2YHJwghYq8Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: mellerudgG5o4pwLWlMeta?.name ?? "mellerud___de",
    path: mellerudgG5o4pwLWlMeta?.path ?? "/de/mellerud",
    meta: mellerudgG5o4pwLWlMeta || {},
    alias: mellerudgG5o4pwLWlMeta?.alias || [],
    redirect: mellerudgG5o4pwLWlMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/mellerud.vue").then(m => m.default || m)
  },
  {
    name: mellerudgG5o4pwLWlMeta?.name ?? "mellerud___en",
    path: mellerudgG5o4pwLWlMeta?.path ?? "/en/mellerud",
    meta: mellerudgG5o4pwLWlMeta || {},
    alias: mellerudgG5o4pwLWlMeta?.alias || [],
    redirect: mellerudgG5o4pwLWlMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/mellerud.vue").then(m => m.default || m)
  },
  {
    name: mellerudgG5o4pwLWlMeta?.name ?? "mellerud___fr",
    path: mellerudgG5o4pwLWlMeta?.path ?? "/fr/mellerud",
    meta: mellerudgG5o4pwLWlMeta || {},
    alias: mellerudgG5o4pwLWlMeta?.alias || [],
    redirect: mellerudgG5o4pwLWlMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/mellerud.vue").then(m => m.default || m)
  },
  {
    name: mellerudgG5o4pwLWlMeta?.name ?? "mellerud___cs",
    path: mellerudgG5o4pwLWlMeta?.path ?? "/cs/mellerud",
    meta: mellerudgG5o4pwLWlMeta || {},
    alias: mellerudgG5o4pwLWlMeta?.alias || [],
    redirect: mellerudgG5o4pwLWlMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/mellerud.vue").then(m => m.default || m)
  },
  {
    name: mellerudgG5o4pwLWlMeta?.name ?? "mellerud___it",
    path: mellerudgG5o4pwLWlMeta?.path ?? "/it/mellerud",
    meta: mellerudgG5o4pwLWlMeta || {},
    alias: mellerudgG5o4pwLWlMeta?.alias || [],
    redirect: mellerudgG5o4pwLWlMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/mellerud.vue").then(m => m.default || m)
  },
  {
    name: mellerudgG5o4pwLWlMeta?.name ?? "mellerud___pl",
    path: mellerudgG5o4pwLWlMeta?.path ?? "/pl/mellerud",
    meta: mellerudgG5o4pwLWlMeta || {},
    alias: mellerudgG5o4pwLWlMeta?.alias || [],
    redirect: mellerudgG5o4pwLWlMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/mellerud.vue").then(m => m.default || m)
  },
  {
    name: mymueslikGUV7ALafiMeta?.name ?? "mymuesli___de",
    path: mymueslikGUV7ALafiMeta?.path ?? "/de/mymuesli",
    meta: mymueslikGUV7ALafiMeta || {},
    alias: mymueslikGUV7ALafiMeta?.alias || [],
    redirect: mymueslikGUV7ALafiMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/mymuesli.vue").then(m => m.default || m)
  },
  {
    name: mymueslikGUV7ALafiMeta?.name ?? "mymuesli___en",
    path: mymueslikGUV7ALafiMeta?.path ?? "/en/mymuesli",
    meta: mymueslikGUV7ALafiMeta || {},
    alias: mymueslikGUV7ALafiMeta?.alias || [],
    redirect: mymueslikGUV7ALafiMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/mymuesli.vue").then(m => m.default || m)
  },
  {
    name: mymueslikGUV7ALafiMeta?.name ?? "mymuesli___fr",
    path: mymueslikGUV7ALafiMeta?.path ?? "/fr/mymuesli",
    meta: mymueslikGUV7ALafiMeta || {},
    alias: mymueslikGUV7ALafiMeta?.alias || [],
    redirect: mymueslikGUV7ALafiMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/mymuesli.vue").then(m => m.default || m)
  },
  {
    name: mymueslikGUV7ALafiMeta?.name ?? "mymuesli___cs",
    path: mymueslikGUV7ALafiMeta?.path ?? "/cs/mymuesli",
    meta: mymueslikGUV7ALafiMeta || {},
    alias: mymueslikGUV7ALafiMeta?.alias || [],
    redirect: mymueslikGUV7ALafiMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/mymuesli.vue").then(m => m.default || m)
  },
  {
    name: mymueslikGUV7ALafiMeta?.name ?? "mymuesli___it",
    path: mymueslikGUV7ALafiMeta?.path ?? "/it/mymuesli",
    meta: mymueslikGUV7ALafiMeta || {},
    alias: mymueslikGUV7ALafiMeta?.alias || [],
    redirect: mymueslikGUV7ALafiMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/mymuesli.vue").then(m => m.default || m)
  },
  {
    name: mymueslikGUV7ALafiMeta?.name ?? "mymuesli___pl",
    path: mymueslikGUV7ALafiMeta?.path ?? "/pl/mymuesli",
    meta: mymueslikGUV7ALafiMeta || {},
    alias: mymueslikGUV7ALafiMeta?.alias || [],
    redirect: mymueslikGUV7ALafiMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/mymuesli.vue").then(m => m.default || m)
  },
  {
    name: mymusliklQxaYiXkXMeta?.name ?? "mymusli___de",
    path: mymusliklQxaYiXkXMeta?.path ?? "/de/mymusli",
    meta: mymusliklQxaYiXkXMeta || {},
    alias: mymusliklQxaYiXkXMeta?.alias || [],
    redirect: mymusliklQxaYiXkXMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/mymusli.vue").then(m => m.default || m)
  },
  {
    name: mymusliklQxaYiXkXMeta?.name ?? "mymusli___en",
    path: mymusliklQxaYiXkXMeta?.path ?? "/en/mymusli",
    meta: mymusliklQxaYiXkXMeta || {},
    alias: mymusliklQxaYiXkXMeta?.alias || [],
    redirect: mymusliklQxaYiXkXMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/mymusli.vue").then(m => m.default || m)
  },
  {
    name: mymusliklQxaYiXkXMeta?.name ?? "mymusli___fr",
    path: mymusliklQxaYiXkXMeta?.path ?? "/fr/mymusli",
    meta: mymusliklQxaYiXkXMeta || {},
    alias: mymusliklQxaYiXkXMeta?.alias || [],
    redirect: mymusliklQxaYiXkXMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/mymusli.vue").then(m => m.default || m)
  },
  {
    name: mymusliklQxaYiXkXMeta?.name ?? "mymusli___cs",
    path: mymusliklQxaYiXkXMeta?.path ?? "/cs/mymusli",
    meta: mymusliklQxaYiXkXMeta || {},
    alias: mymusliklQxaYiXkXMeta?.alias || [],
    redirect: mymusliklQxaYiXkXMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/mymusli.vue").then(m => m.default || m)
  },
  {
    name: mymusliklQxaYiXkXMeta?.name ?? "mymusli___it",
    path: mymusliklQxaYiXkXMeta?.path ?? "/it/mymusli",
    meta: mymusliklQxaYiXkXMeta || {},
    alias: mymusliklQxaYiXkXMeta?.alias || [],
    redirect: mymusliklQxaYiXkXMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/mymusli.vue").then(m => m.default || m)
  },
  {
    name: mymusliklQxaYiXkXMeta?.name ?? "mymusli___pl",
    path: mymusliklQxaYiXkXMeta?.path ?? "/pl/mymusli",
    meta: mymusliklQxaYiXkXMeta || {},
    alias: mymusliklQxaYiXkXMeta?.alias || [],
    redirect: mymusliklQxaYiXkXMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/mymusli.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45confirmationLwkJHJjyJyMeta?.name ?? "newsletter-confirmation___de",
    path: newsletter_45confirmationLwkJHJjyJyMeta?.path ?? "/de/newsletter-confirmation",
    meta: newsletter_45confirmationLwkJHJjyJyMeta || {},
    alias: newsletter_45confirmationLwkJHJjyJyMeta?.alias || [],
    redirect: newsletter_45confirmationLwkJHJjyJyMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45confirmationLwkJHJjyJyMeta?.name ?? "newsletter-confirmation___en",
    path: newsletter_45confirmationLwkJHJjyJyMeta?.path ?? "/en/newsletter-confirmation",
    meta: newsletter_45confirmationLwkJHJjyJyMeta || {},
    alias: newsletter_45confirmationLwkJHJjyJyMeta?.alias || [],
    redirect: newsletter_45confirmationLwkJHJjyJyMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45confirmationLwkJHJjyJyMeta?.name ?? "newsletter-confirmation___fr",
    path: newsletter_45confirmationLwkJHJjyJyMeta?.path ?? "/fr/newsletter-confirmation",
    meta: newsletter_45confirmationLwkJHJjyJyMeta || {},
    alias: newsletter_45confirmationLwkJHJjyJyMeta?.alias || [],
    redirect: newsletter_45confirmationLwkJHJjyJyMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45confirmationLwkJHJjyJyMeta?.name ?? "newsletter-confirmation___cs",
    path: newsletter_45confirmationLwkJHJjyJyMeta?.path ?? "/cs/newsletter-confirmation",
    meta: newsletter_45confirmationLwkJHJjyJyMeta || {},
    alias: newsletter_45confirmationLwkJHJjyJyMeta?.alias || [],
    redirect: newsletter_45confirmationLwkJHJjyJyMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45confirmationLwkJHJjyJyMeta?.name ?? "newsletter-confirmation___it",
    path: newsletter_45confirmationLwkJHJjyJyMeta?.path ?? "/it/newsletter-confirmation",
    meta: newsletter_45confirmationLwkJHJjyJyMeta || {},
    alias: newsletter_45confirmationLwkJHJjyJyMeta?.alias || [],
    redirect: newsletter_45confirmationLwkJHJjyJyMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: newsletter_45confirmationLwkJHJjyJyMeta?.name ?? "newsletter-confirmation___pl",
    path: newsletter_45confirmationLwkJHJjyJyMeta?.path ?? "/pl/newsletter-confirmation",
    meta: newsletter_45confirmationLwkJHJjyJyMeta || {},
    alias: newsletter_45confirmationLwkJHJjyJyMeta?.alias || [],
    redirect: newsletter_45confirmationLwkJHJjyJyMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/newsletter-confirmation.vue").then(m => m.default || m)
  },
  {
    name: addressRRdvIUxF45Meta?.name ?? "order-address___de",
    path: addressRRdvIUxF45Meta?.path ?? "/de/order/address",
    meta: addressRRdvIUxF45Meta || {},
    alias: addressRRdvIUxF45Meta?.alias || [],
    redirect: addressRRdvIUxF45Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/address.vue").then(m => m.default || m)
  },
  {
    name: addressRRdvIUxF45Meta?.name ?? "order-address___en",
    path: addressRRdvIUxF45Meta?.path ?? "/en/order/address",
    meta: addressRRdvIUxF45Meta || {},
    alias: addressRRdvIUxF45Meta?.alias || [],
    redirect: addressRRdvIUxF45Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/address.vue").then(m => m.default || m)
  },
  {
    name: addressRRdvIUxF45Meta?.name ?? "order-address___fr",
    path: addressRRdvIUxF45Meta?.path ?? "/fr/order/address",
    meta: addressRRdvIUxF45Meta || {},
    alias: addressRRdvIUxF45Meta?.alias || [],
    redirect: addressRRdvIUxF45Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/address.vue").then(m => m.default || m)
  },
  {
    name: addressRRdvIUxF45Meta?.name ?? "order-address___cs",
    path: addressRRdvIUxF45Meta?.path ?? "/cs/order/address",
    meta: addressRRdvIUxF45Meta || {},
    alias: addressRRdvIUxF45Meta?.alias || [],
    redirect: addressRRdvIUxF45Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/address.vue").then(m => m.default || m)
  },
  {
    name: addressRRdvIUxF45Meta?.name ?? "order-address___it",
    path: addressRRdvIUxF45Meta?.path ?? "/it/order/address",
    meta: addressRRdvIUxF45Meta || {},
    alias: addressRRdvIUxF45Meta?.alias || [],
    redirect: addressRRdvIUxF45Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/address.vue").then(m => m.default || m)
  },
  {
    name: addressRRdvIUxF45Meta?.name ?? "order-address___pl",
    path: addressRRdvIUxF45Meta?.path ?? "/pl/order/address",
    meta: addressRRdvIUxF45Meta || {},
    alias: addressRRdvIUxF45Meta?.alias || [],
    redirect: addressRRdvIUxF45Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/address.vue").then(m => m.default || m)
  },
  {
    name: cardjUA5ZTsqEKMeta?.name ?? "order-card___de",
    path: cardjUA5ZTsqEKMeta?.path ?? "/de/order/card",
    meta: cardjUA5ZTsqEKMeta || {},
    alias: cardjUA5ZTsqEKMeta?.alias || [],
    redirect: cardjUA5ZTsqEKMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/card.vue").then(m => m.default || m)
  },
  {
    name: cardjUA5ZTsqEKMeta?.name ?? "order-card___en",
    path: cardjUA5ZTsqEKMeta?.path ?? "/en/order/card",
    meta: cardjUA5ZTsqEKMeta || {},
    alias: cardjUA5ZTsqEKMeta?.alias || [],
    redirect: cardjUA5ZTsqEKMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/card.vue").then(m => m.default || m)
  },
  {
    name: cardjUA5ZTsqEKMeta?.name ?? "order-card___fr",
    path: cardjUA5ZTsqEKMeta?.path ?? "/fr/order/card",
    meta: cardjUA5ZTsqEKMeta || {},
    alias: cardjUA5ZTsqEKMeta?.alias || [],
    redirect: cardjUA5ZTsqEKMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/card.vue").then(m => m.default || m)
  },
  {
    name: cardjUA5ZTsqEKMeta?.name ?? "order-card___cs",
    path: cardjUA5ZTsqEKMeta?.path ?? "/cs/order/card",
    meta: cardjUA5ZTsqEKMeta || {},
    alias: cardjUA5ZTsqEKMeta?.alias || [],
    redirect: cardjUA5ZTsqEKMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/card.vue").then(m => m.default || m)
  },
  {
    name: cardjUA5ZTsqEKMeta?.name ?? "order-card___it",
    path: cardjUA5ZTsqEKMeta?.path ?? "/it/order/card",
    meta: cardjUA5ZTsqEKMeta || {},
    alias: cardjUA5ZTsqEKMeta?.alias || [],
    redirect: cardjUA5ZTsqEKMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/card.vue").then(m => m.default || m)
  },
  {
    name: cardjUA5ZTsqEKMeta?.name ?? "order-card___pl",
    path: cardjUA5ZTsqEKMeta?.path ?? "/pl/order/card",
    meta: cardjUA5ZTsqEKMeta || {},
    alias: cardjUA5ZTsqEKMeta?.alias || [],
    redirect: cardjUA5ZTsqEKMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/card.vue").then(m => m.default || m)
  },
  {
    name: login0VG7pLfhqfMeta?.name ?? "order-login___de",
    path: login0VG7pLfhqfMeta?.path ?? "/de/order/login",
    meta: login0VG7pLfhqfMeta || {},
    alias: login0VG7pLfhqfMeta?.alias || [],
    redirect: login0VG7pLfhqfMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/login.vue").then(m => m.default || m)
  },
  {
    name: login0VG7pLfhqfMeta?.name ?? "order-login___en",
    path: login0VG7pLfhqfMeta?.path ?? "/en/order/login",
    meta: login0VG7pLfhqfMeta || {},
    alias: login0VG7pLfhqfMeta?.alias || [],
    redirect: login0VG7pLfhqfMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/login.vue").then(m => m.default || m)
  },
  {
    name: login0VG7pLfhqfMeta?.name ?? "order-login___fr",
    path: login0VG7pLfhqfMeta?.path ?? "/fr/order/login",
    meta: login0VG7pLfhqfMeta || {},
    alias: login0VG7pLfhqfMeta?.alias || [],
    redirect: login0VG7pLfhqfMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/login.vue").then(m => m.default || m)
  },
  {
    name: login0VG7pLfhqfMeta?.name ?? "order-login___cs",
    path: login0VG7pLfhqfMeta?.path ?? "/cs/order/login",
    meta: login0VG7pLfhqfMeta || {},
    alias: login0VG7pLfhqfMeta?.alias || [],
    redirect: login0VG7pLfhqfMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/login.vue").then(m => m.default || m)
  },
  {
    name: login0VG7pLfhqfMeta?.name ?? "order-login___it",
    path: login0VG7pLfhqfMeta?.path ?? "/it/order/login",
    meta: login0VG7pLfhqfMeta || {},
    alias: login0VG7pLfhqfMeta?.alias || [],
    redirect: login0VG7pLfhqfMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/login.vue").then(m => m.default || m)
  },
  {
    name: login0VG7pLfhqfMeta?.name ?? "order-login___pl",
    path: login0VG7pLfhqfMeta?.path ?? "/pl/order/login",
    meta: login0VG7pLfhqfMeta || {},
    alias: login0VG7pLfhqfMeta?.alias || [],
    redirect: login0VG7pLfhqfMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/login.vue").then(m => m.default || m)
  },
  {
    name: overviewDpi4KRAgfiMeta?.name ?? "order-overview___de",
    path: overviewDpi4KRAgfiMeta?.path ?? "/de/order/overview",
    meta: overviewDpi4KRAgfiMeta || {},
    alias: overviewDpi4KRAgfiMeta?.alias || [],
    redirect: overviewDpi4KRAgfiMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/overview.vue").then(m => m.default || m)
  },
  {
    name: overviewDpi4KRAgfiMeta?.name ?? "order-overview___en",
    path: overviewDpi4KRAgfiMeta?.path ?? "/en/order/overview",
    meta: overviewDpi4KRAgfiMeta || {},
    alias: overviewDpi4KRAgfiMeta?.alias || [],
    redirect: overviewDpi4KRAgfiMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/overview.vue").then(m => m.default || m)
  },
  {
    name: overviewDpi4KRAgfiMeta?.name ?? "order-overview___fr",
    path: overviewDpi4KRAgfiMeta?.path ?? "/fr/order/overview",
    meta: overviewDpi4KRAgfiMeta || {},
    alias: overviewDpi4KRAgfiMeta?.alias || [],
    redirect: overviewDpi4KRAgfiMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/overview.vue").then(m => m.default || m)
  },
  {
    name: overviewDpi4KRAgfiMeta?.name ?? "order-overview___cs",
    path: overviewDpi4KRAgfiMeta?.path ?? "/cs/order/overview",
    meta: overviewDpi4KRAgfiMeta || {},
    alias: overviewDpi4KRAgfiMeta?.alias || [],
    redirect: overviewDpi4KRAgfiMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/overview.vue").then(m => m.default || m)
  },
  {
    name: overviewDpi4KRAgfiMeta?.name ?? "order-overview___it",
    path: overviewDpi4KRAgfiMeta?.path ?? "/it/order/overview",
    meta: overviewDpi4KRAgfiMeta || {},
    alias: overviewDpi4KRAgfiMeta?.alias || [],
    redirect: overviewDpi4KRAgfiMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/overview.vue").then(m => m.default || m)
  },
  {
    name: overviewDpi4KRAgfiMeta?.name ?? "order-overview___pl",
    path: overviewDpi4KRAgfiMeta?.path ?? "/pl/order/overview",
    meta: overviewDpi4KRAgfiMeta || {},
    alias: overviewDpi4KRAgfiMeta?.alias || [],
    redirect: overviewDpi4KRAgfiMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/overview.vue").then(m => m.default || m)
  },
  {
    name: printdatawCBp4soi4sMeta?.name ?? "order-printdata___de",
    path: printdatawCBp4soi4sMeta?.path ?? "/de/order/printdata",
    meta: printdatawCBp4soi4sMeta || {},
    alias: printdatawCBp4soi4sMeta?.alias || [],
    redirect: printdatawCBp4soi4sMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/printdata.vue").then(m => m.default || m)
  },
  {
    name: printdatawCBp4soi4sMeta?.name ?? "order-printdata___en",
    path: printdatawCBp4soi4sMeta?.path ?? "/en/order/printdata",
    meta: printdatawCBp4soi4sMeta || {},
    alias: printdatawCBp4soi4sMeta?.alias || [],
    redirect: printdatawCBp4soi4sMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/printdata.vue").then(m => m.default || m)
  },
  {
    name: printdatawCBp4soi4sMeta?.name ?? "order-printdata___fr",
    path: printdatawCBp4soi4sMeta?.path ?? "/fr/order/printdata",
    meta: printdatawCBp4soi4sMeta || {},
    alias: printdatawCBp4soi4sMeta?.alias || [],
    redirect: printdatawCBp4soi4sMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/printdata.vue").then(m => m.default || m)
  },
  {
    name: printdatawCBp4soi4sMeta?.name ?? "order-printdata___cs",
    path: printdatawCBp4soi4sMeta?.path ?? "/cs/order/printdata",
    meta: printdatawCBp4soi4sMeta || {},
    alias: printdatawCBp4soi4sMeta?.alias || [],
    redirect: printdatawCBp4soi4sMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/printdata.vue").then(m => m.default || m)
  },
  {
    name: printdatawCBp4soi4sMeta?.name ?? "order-printdata___it",
    path: printdatawCBp4soi4sMeta?.path ?? "/it/order/printdata",
    meta: printdatawCBp4soi4sMeta || {},
    alias: printdatawCBp4soi4sMeta?.alias || [],
    redirect: printdatawCBp4soi4sMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/printdata.vue").then(m => m.default || m)
  },
  {
    name: printdatawCBp4soi4sMeta?.name ?? "order-printdata___pl",
    path: printdatawCBp4soi4sMeta?.path ?? "/pl/order/printdata",
    meta: printdatawCBp4soi4sMeta || {},
    alias: printdatawCBp4soi4sMeta?.alias || [],
    redirect: printdatawCBp4soi4sMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/printdata.vue").then(m => m.default || m)
  },
  {
    name: shipping8QRsAaIan2Meta?.name ?? "order-shipping___de",
    path: shipping8QRsAaIan2Meta?.path ?? "/de/order/shipping",
    meta: shipping8QRsAaIan2Meta || {},
    alias: shipping8QRsAaIan2Meta?.alias || [],
    redirect: shipping8QRsAaIan2Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/shipping.vue").then(m => m.default || m)
  },
  {
    name: shipping8QRsAaIan2Meta?.name ?? "order-shipping___en",
    path: shipping8QRsAaIan2Meta?.path ?? "/en/order/shipping",
    meta: shipping8QRsAaIan2Meta || {},
    alias: shipping8QRsAaIan2Meta?.alias || [],
    redirect: shipping8QRsAaIan2Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/shipping.vue").then(m => m.default || m)
  },
  {
    name: shipping8QRsAaIan2Meta?.name ?? "order-shipping___fr",
    path: shipping8QRsAaIan2Meta?.path ?? "/fr/order/shipping",
    meta: shipping8QRsAaIan2Meta || {},
    alias: shipping8QRsAaIan2Meta?.alias || [],
    redirect: shipping8QRsAaIan2Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/shipping.vue").then(m => m.default || m)
  },
  {
    name: shipping8QRsAaIan2Meta?.name ?? "order-shipping___cs",
    path: shipping8QRsAaIan2Meta?.path ?? "/cs/order/shipping",
    meta: shipping8QRsAaIan2Meta || {},
    alias: shipping8QRsAaIan2Meta?.alias || [],
    redirect: shipping8QRsAaIan2Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/shipping.vue").then(m => m.default || m)
  },
  {
    name: shipping8QRsAaIan2Meta?.name ?? "order-shipping___it",
    path: shipping8QRsAaIan2Meta?.path ?? "/it/order/shipping",
    meta: shipping8QRsAaIan2Meta || {},
    alias: shipping8QRsAaIan2Meta?.alias || [],
    redirect: shipping8QRsAaIan2Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/shipping.vue").then(m => m.default || m)
  },
  {
    name: shipping8QRsAaIan2Meta?.name ?? "order-shipping___pl",
    path: shipping8QRsAaIan2Meta?.path ?? "/pl/order/shipping",
    meta: shipping8QRsAaIan2Meta || {},
    alias: shipping8QRsAaIan2Meta?.alias || [],
    redirect: shipping8QRsAaIan2Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/shipping.vue").then(m => m.default || m)
  },
  {
    name: thank_45youw64uWVYufPMeta?.name ?? "order-thank-you___de",
    path: thank_45youw64uWVYufPMeta?.path ?? "/de/order/thank-you",
    meta: thank_45youw64uWVYufPMeta || {},
    alias: thank_45youw64uWVYufPMeta?.alias || [],
    redirect: thank_45youw64uWVYufPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youw64uWVYufPMeta?.name ?? "order-thank-you___en",
    path: thank_45youw64uWVYufPMeta?.path ?? "/en/order/thank-you",
    meta: thank_45youw64uWVYufPMeta || {},
    alias: thank_45youw64uWVYufPMeta?.alias || [],
    redirect: thank_45youw64uWVYufPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youw64uWVYufPMeta?.name ?? "order-thank-you___fr",
    path: thank_45youw64uWVYufPMeta?.path ?? "/fr/order/thank-you",
    meta: thank_45youw64uWVYufPMeta || {},
    alias: thank_45youw64uWVYufPMeta?.alias || [],
    redirect: thank_45youw64uWVYufPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youw64uWVYufPMeta?.name ?? "order-thank-you___cs",
    path: thank_45youw64uWVYufPMeta?.path ?? "/cs/order/thank-you",
    meta: thank_45youw64uWVYufPMeta || {},
    alias: thank_45youw64uWVYufPMeta?.alias || [],
    redirect: thank_45youw64uWVYufPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youw64uWVYufPMeta?.name ?? "order-thank-you___it",
    path: thank_45youw64uWVYufPMeta?.path ?? "/it/order/thank-you",
    meta: thank_45youw64uWVYufPMeta || {},
    alias: thank_45youw64uWVYufPMeta?.alias || [],
    redirect: thank_45youw64uWVYufPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youw64uWVYufPMeta?.name ?? "order-thank-you___pl",
    path: thank_45youw64uWVYufPMeta?.path ?? "/pl/order/thank-you",
    meta: thank_45youw64uWVYufPMeta || {},
    alias: thank_45youw64uWVYufPMeta?.alias || [],
    redirect: thank_45youw64uWVYufPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/order/thank-you.vue").then(m => m.default || m)
  },
  {
    name: our_45promiseQiurQDHWlIMeta?.name ?? "our-promise___de",
    path: our_45promiseQiurQDHWlIMeta?.path ?? "/de/our-promise",
    meta: our_45promiseQiurQDHWlIMeta || {},
    alias: our_45promiseQiurQDHWlIMeta?.alias || [],
    redirect: our_45promiseQiurQDHWlIMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/our-promise.vue").then(m => m.default || m)
  },
  {
    name: our_45promiseQiurQDHWlIMeta?.name ?? "our-promise___en",
    path: our_45promiseQiurQDHWlIMeta?.path ?? "/en/our-promise",
    meta: our_45promiseQiurQDHWlIMeta || {},
    alias: our_45promiseQiurQDHWlIMeta?.alias || [],
    redirect: our_45promiseQiurQDHWlIMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/our-promise.vue").then(m => m.default || m)
  },
  {
    name: our_45promiseQiurQDHWlIMeta?.name ?? "our-promise___fr",
    path: our_45promiseQiurQDHWlIMeta?.path ?? "/fr/our-promise",
    meta: our_45promiseQiurQDHWlIMeta || {},
    alias: our_45promiseQiurQDHWlIMeta?.alias || [],
    redirect: our_45promiseQiurQDHWlIMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/our-promise.vue").then(m => m.default || m)
  },
  {
    name: our_45promiseQiurQDHWlIMeta?.name ?? "our-promise___cs",
    path: our_45promiseQiurQDHWlIMeta?.path ?? "/cs/our-promise",
    meta: our_45promiseQiurQDHWlIMeta || {},
    alias: our_45promiseQiurQDHWlIMeta?.alias || [],
    redirect: our_45promiseQiurQDHWlIMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/our-promise.vue").then(m => m.default || m)
  },
  {
    name: our_45promiseQiurQDHWlIMeta?.name ?? "our-promise___it",
    path: our_45promiseQiurQDHWlIMeta?.path ?? "/it/our-promise",
    meta: our_45promiseQiurQDHWlIMeta || {},
    alias: our_45promiseQiurQDHWlIMeta?.alias || [],
    redirect: our_45promiseQiurQDHWlIMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/our-promise.vue").then(m => m.default || m)
  },
  {
    name: our_45promiseQiurQDHWlIMeta?.name ?? "our-promise___pl",
    path: our_45promiseQiurQDHWlIMeta?.path ?? "/pl/our-promise",
    meta: our_45promiseQiurQDHWlIMeta || {},
    alias: our_45promiseQiurQDHWlIMeta?.alias || [],
    redirect: our_45promiseQiurQDHWlIMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/our-promise.vue").then(m => m.default || m)
  },
  {
    name: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.name ?? "package-leaflets-package-inserts-form___de",
    path: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.path ?? "/de/package-leaflets-package-inserts-form",
    meta: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta || {},
    alias: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.alias || [],
    redirect: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue").then(m => m.default || m)
  },
  {
    name: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.name ?? "package-leaflets-package-inserts-form___en",
    path: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.path ?? "/en/package-leaflets-package-inserts-form",
    meta: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta || {},
    alias: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.alias || [],
    redirect: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue").then(m => m.default || m)
  },
  {
    name: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.name ?? "package-leaflets-package-inserts-form___fr",
    path: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.path ?? "/fr/package-leaflets-package-inserts-form",
    meta: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta || {},
    alias: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.alias || [],
    redirect: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue").then(m => m.default || m)
  },
  {
    name: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.name ?? "package-leaflets-package-inserts-form___cs",
    path: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.path ?? "/cs/package-leaflets-package-inserts-form",
    meta: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta || {},
    alias: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.alias || [],
    redirect: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue").then(m => m.default || m)
  },
  {
    name: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.name ?? "package-leaflets-package-inserts-form___it",
    path: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.path ?? "/it/package-leaflets-package-inserts-form",
    meta: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta || {},
    alias: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.alias || [],
    redirect: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue").then(m => m.default || m)
  },
  {
    name: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.name ?? "package-leaflets-package-inserts-form___pl",
    path: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.path ?? "/pl/package-leaflets-package-inserts-form",
    meta: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta || {},
    alias: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.alias || [],
    redirect: package_45leaflets_45package_45inserts_45formEt80PNDmspMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/package-leaflets-package-inserts-form.vue").then(m => m.default || m)
  },
  {
    name: paypal_listeneryOeCsNs1r8Meta?.name ?? "paypal-paypal_listener___de",
    path: paypal_listeneryOeCsNs1r8Meta?.path ?? "/de/paypal/paypal_listener",
    meta: paypal_listeneryOeCsNs1r8Meta || {},
    alias: paypal_listeneryOeCsNs1r8Meta?.alias || [],
    redirect: paypal_listeneryOeCsNs1r8Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/paypal/paypal_listener.vue").then(m => m.default || m)
  },
  {
    name: paypal_listeneryOeCsNs1r8Meta?.name ?? "paypal-paypal_listener___en",
    path: paypal_listeneryOeCsNs1r8Meta?.path ?? "/en/paypal/paypal_listener",
    meta: paypal_listeneryOeCsNs1r8Meta || {},
    alias: paypal_listeneryOeCsNs1r8Meta?.alias || [],
    redirect: paypal_listeneryOeCsNs1r8Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/paypal/paypal_listener.vue").then(m => m.default || m)
  },
  {
    name: paypal_listeneryOeCsNs1r8Meta?.name ?? "paypal-paypal_listener___fr",
    path: paypal_listeneryOeCsNs1r8Meta?.path ?? "/fr/paypal/paypal_listener",
    meta: paypal_listeneryOeCsNs1r8Meta || {},
    alias: paypal_listeneryOeCsNs1r8Meta?.alias || [],
    redirect: paypal_listeneryOeCsNs1r8Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/paypal/paypal_listener.vue").then(m => m.default || m)
  },
  {
    name: paypal_listeneryOeCsNs1r8Meta?.name ?? "paypal-paypal_listener___cs",
    path: paypal_listeneryOeCsNs1r8Meta?.path ?? "/cs/paypal/paypal_listener",
    meta: paypal_listeneryOeCsNs1r8Meta || {},
    alias: paypal_listeneryOeCsNs1r8Meta?.alias || [],
    redirect: paypal_listeneryOeCsNs1r8Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/paypal/paypal_listener.vue").then(m => m.default || m)
  },
  {
    name: paypal_listeneryOeCsNs1r8Meta?.name ?? "paypal-paypal_listener___it",
    path: paypal_listeneryOeCsNs1r8Meta?.path ?? "/it/paypal/paypal_listener",
    meta: paypal_listeneryOeCsNs1r8Meta || {},
    alias: paypal_listeneryOeCsNs1r8Meta?.alias || [],
    redirect: paypal_listeneryOeCsNs1r8Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/paypal/paypal_listener.vue").then(m => m.default || m)
  },
  {
    name: paypal_listeneryOeCsNs1r8Meta?.name ?? "paypal-paypal_listener___pl",
    path: paypal_listeneryOeCsNs1r8Meta?.path ?? "/pl/paypal/paypal_listener",
    meta: paypal_listeneryOeCsNs1r8Meta || {},
    alias: paypal_listeneryOeCsNs1r8Meta?.alias || [],
    redirect: paypal_listeneryOeCsNs1r8Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/paypal/paypal_listener.vue").then(m => m.default || m)
  },
  {
    name: index41xcvZ4TrPMeta?.name ?? "print-data___de",
    path: index41xcvZ4TrPMeta?.path ?? "/de/print-data",
    meta: index41xcvZ4TrPMeta || {},
    alias: index41xcvZ4TrPMeta?.alias || [],
    redirect: index41xcvZ4TrPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/print-data/index.vue").then(m => m.default || m)
  },
  {
    name: index41xcvZ4TrPMeta?.name ?? "print-data___en",
    path: index41xcvZ4TrPMeta?.path ?? "/en/print-data",
    meta: index41xcvZ4TrPMeta || {},
    alias: index41xcvZ4TrPMeta?.alias || [],
    redirect: index41xcvZ4TrPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/print-data/index.vue").then(m => m.default || m)
  },
  {
    name: index41xcvZ4TrPMeta?.name ?? "print-data___fr",
    path: index41xcvZ4TrPMeta?.path ?? "/fr/print-data",
    meta: index41xcvZ4TrPMeta || {},
    alias: index41xcvZ4TrPMeta?.alias || [],
    redirect: index41xcvZ4TrPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/print-data/index.vue").then(m => m.default || m)
  },
  {
    name: index41xcvZ4TrPMeta?.name ?? "print-data___cs",
    path: index41xcvZ4TrPMeta?.path ?? "/cs/print-data",
    meta: index41xcvZ4TrPMeta || {},
    alias: index41xcvZ4TrPMeta?.alias || [],
    redirect: index41xcvZ4TrPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/print-data/index.vue").then(m => m.default || m)
  },
  {
    name: index41xcvZ4TrPMeta?.name ?? "print-data___it",
    path: index41xcvZ4TrPMeta?.path ?? "/it/print-data",
    meta: index41xcvZ4TrPMeta || {},
    alias: index41xcvZ4TrPMeta?.alias || [],
    redirect: index41xcvZ4TrPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/print-data/index.vue").then(m => m.default || m)
  },
  {
    name: index41xcvZ4TrPMeta?.name ?? "print-data___pl",
    path: index41xcvZ4TrPMeta?.path ?? "/pl/print-data",
    meta: index41xcvZ4TrPMeta || {},
    alias: index41xcvZ4TrPMeta?.alias || [],
    redirect: index41xcvZ4TrPMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/print-data/index.vue").then(m => m.default || m)
  },
  {
    name: repairknkkhZuJPqMeta?.name ?? "print-data-repair___de",
    path: repairknkkhZuJPqMeta?.path ?? "/de/print-data/repair",
    meta: repairknkkhZuJPqMeta || {},
    alias: repairknkkhZuJPqMeta?.alias || [],
    redirect: repairknkkhZuJPqMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/print-data/repair.vue").then(m => m.default || m)
  },
  {
    name: repairknkkhZuJPqMeta?.name ?? "print-data-repair___en",
    path: repairknkkhZuJPqMeta?.path ?? "/en/print-data/repair",
    meta: repairknkkhZuJPqMeta || {},
    alias: repairknkkhZuJPqMeta?.alias || [],
    redirect: repairknkkhZuJPqMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/print-data/repair.vue").then(m => m.default || m)
  },
  {
    name: repairknkkhZuJPqMeta?.name ?? "print-data-repair___fr",
    path: repairknkkhZuJPqMeta?.path ?? "/fr/print-data/repair",
    meta: repairknkkhZuJPqMeta || {},
    alias: repairknkkhZuJPqMeta?.alias || [],
    redirect: repairknkkhZuJPqMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/print-data/repair.vue").then(m => m.default || m)
  },
  {
    name: repairknkkhZuJPqMeta?.name ?? "print-data-repair___cs",
    path: repairknkkhZuJPqMeta?.path ?? "/cs/print-data/repair",
    meta: repairknkkhZuJPqMeta || {},
    alias: repairknkkhZuJPqMeta?.alias || [],
    redirect: repairknkkhZuJPqMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/print-data/repair.vue").then(m => m.default || m)
  },
  {
    name: repairknkkhZuJPqMeta?.name ?? "print-data-repair___it",
    path: repairknkkhZuJPqMeta?.path ?? "/it/print-data/repair",
    meta: repairknkkhZuJPqMeta || {},
    alias: repairknkkhZuJPqMeta?.alias || [],
    redirect: repairknkkhZuJPqMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/print-data/repair.vue").then(m => m.default || m)
  },
  {
    name: repairknkkhZuJPqMeta?.name ?? "print-data-repair___pl",
    path: repairknkkhZuJPqMeta?.path ?? "/pl/print-data/repair",
    meta: repairknkkhZuJPqMeta || {},
    alias: repairknkkhZuJPqMeta?.alias || [],
    redirect: repairknkkhZuJPqMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/print-data/repair.vue").then(m => m.default || m)
  },
  {
    name: printed_45labels_45formJKMDMMt0yFMeta?.name ?? "printed-labels-form___de",
    path: printed_45labels_45formJKMDMMt0yFMeta?.path ?? "/de/printed-labels-form",
    meta: printed_45labels_45formJKMDMMt0yFMeta || {},
    alias: printed_45labels_45formJKMDMMt0yFMeta?.alias || [],
    redirect: printed_45labels_45formJKMDMMt0yFMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/printed-labels-form.vue").then(m => m.default || m)
  },
  {
    name: printed_45labels_45formJKMDMMt0yFMeta?.name ?? "printed-labels-form___en",
    path: printed_45labels_45formJKMDMMt0yFMeta?.path ?? "/en/printed-labels-form",
    meta: printed_45labels_45formJKMDMMt0yFMeta || {},
    alias: printed_45labels_45formJKMDMMt0yFMeta?.alias || [],
    redirect: printed_45labels_45formJKMDMMt0yFMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/printed-labels-form.vue").then(m => m.default || m)
  },
  {
    name: printed_45labels_45formJKMDMMt0yFMeta?.name ?? "printed-labels-form___fr",
    path: printed_45labels_45formJKMDMMt0yFMeta?.path ?? "/fr/printed-labels-form",
    meta: printed_45labels_45formJKMDMMt0yFMeta || {},
    alias: printed_45labels_45formJKMDMMt0yFMeta?.alias || [],
    redirect: printed_45labels_45formJKMDMMt0yFMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/printed-labels-form.vue").then(m => m.default || m)
  },
  {
    name: printed_45labels_45formJKMDMMt0yFMeta?.name ?? "printed-labels-form___cs",
    path: printed_45labels_45formJKMDMMt0yFMeta?.path ?? "/cs/printed-labels-form",
    meta: printed_45labels_45formJKMDMMt0yFMeta || {},
    alias: printed_45labels_45formJKMDMMt0yFMeta?.alias || [],
    redirect: printed_45labels_45formJKMDMMt0yFMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/printed-labels-form.vue").then(m => m.default || m)
  },
  {
    name: printed_45labels_45formJKMDMMt0yFMeta?.name ?? "printed-labels-form___it",
    path: printed_45labels_45formJKMDMMt0yFMeta?.path ?? "/it/printed-labels-form",
    meta: printed_45labels_45formJKMDMMt0yFMeta || {},
    alias: printed_45labels_45formJKMDMMt0yFMeta?.alias || [],
    redirect: printed_45labels_45formJKMDMMt0yFMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/printed-labels-form.vue").then(m => m.default || m)
  },
  {
    name: printed_45labels_45formJKMDMMt0yFMeta?.name ?? "printed-labels-form___pl",
    path: printed_45labels_45formJKMDMMt0yFMeta?.path ?? "/pl/printed-labels-form",
    meta: printed_45labels_45formJKMDMMt0yFMeta || {},
    alias: printed_45labels_45formJKMDMMt0yFMeta?.alias || [],
    redirect: printed_45labels_45formJKMDMMt0yFMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/printed-labels-form.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy2WYyyO2BhYMeta?.name ?? "privacy-policy___de",
    path: privacy_45policy2WYyyO2BhYMeta?.path ?? "/de/privacy-policy",
    meta: privacy_45policy2WYyyO2BhYMeta || {},
    alias: privacy_45policy2WYyyO2BhYMeta?.alias || [],
    redirect: privacy_45policy2WYyyO2BhYMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy2WYyyO2BhYMeta?.name ?? "privacy-policy___en",
    path: privacy_45policy2WYyyO2BhYMeta?.path ?? "/en/privacy-policy",
    meta: privacy_45policy2WYyyO2BhYMeta || {},
    alias: privacy_45policy2WYyyO2BhYMeta?.alias || [],
    redirect: privacy_45policy2WYyyO2BhYMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy2WYyyO2BhYMeta?.name ?? "privacy-policy___fr",
    path: privacy_45policy2WYyyO2BhYMeta?.path ?? "/fr/privacy-policy",
    meta: privacy_45policy2WYyyO2BhYMeta || {},
    alias: privacy_45policy2WYyyO2BhYMeta?.alias || [],
    redirect: privacy_45policy2WYyyO2BhYMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy2WYyyO2BhYMeta?.name ?? "privacy-policy___cs",
    path: privacy_45policy2WYyyO2BhYMeta?.path ?? "/cs/privacy-policy",
    meta: privacy_45policy2WYyyO2BhYMeta || {},
    alias: privacy_45policy2WYyyO2BhYMeta?.alias || [],
    redirect: privacy_45policy2WYyyO2BhYMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy2WYyyO2BhYMeta?.name ?? "privacy-policy___it",
    path: privacy_45policy2WYyyO2BhYMeta?.path ?? "/it/privacy-policy",
    meta: privacy_45policy2WYyyO2BhYMeta || {},
    alias: privacy_45policy2WYyyO2BhYMeta?.alias || [],
    redirect: privacy_45policy2WYyyO2BhYMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy2WYyyO2BhYMeta?.name ?? "privacy-policy___pl",
    path: privacy_45policy2WYyyO2BhYMeta?.path ?? "/pl/privacy-policy",
    meta: privacy_45policy2WYyyO2BhYMeta || {},
    alias: privacy_45policy2WYyyO2BhYMeta?.alias || [],
    redirect: privacy_45policy2WYyyO2BhYMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UhSj1oq5uGMeta?.name ?? "products-slug___de",
    path: _91slug_93UhSj1oq5uGMeta?.path ?? "/de/products/:slug()",
    meta: _91slug_93UhSj1oq5uGMeta || {},
    alias: _91slug_93UhSj1oq5uGMeta?.alias || [],
    redirect: _91slug_93UhSj1oq5uGMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UhSj1oq5uGMeta?.name ?? "products-slug___en",
    path: _91slug_93UhSj1oq5uGMeta?.path ?? "/en/products/:slug()",
    meta: _91slug_93UhSj1oq5uGMeta || {},
    alias: _91slug_93UhSj1oq5uGMeta?.alias || [],
    redirect: _91slug_93UhSj1oq5uGMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UhSj1oq5uGMeta?.name ?? "products-slug___fr",
    path: _91slug_93UhSj1oq5uGMeta?.path ?? "/fr/products/:slug()",
    meta: _91slug_93UhSj1oq5uGMeta || {},
    alias: _91slug_93UhSj1oq5uGMeta?.alias || [],
    redirect: _91slug_93UhSj1oq5uGMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UhSj1oq5uGMeta?.name ?? "products-slug___cs",
    path: _91slug_93UhSj1oq5uGMeta?.path ?? "/cs/products/:slug()",
    meta: _91slug_93UhSj1oq5uGMeta || {},
    alias: _91slug_93UhSj1oq5uGMeta?.alias || [],
    redirect: _91slug_93UhSj1oq5uGMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UhSj1oq5uGMeta?.name ?? "products-slug___it",
    path: _91slug_93UhSj1oq5uGMeta?.path ?? "/it/products/:slug()",
    meta: _91slug_93UhSj1oq5uGMeta || {},
    alias: _91slug_93UhSj1oq5uGMeta?.alias || [],
    redirect: _91slug_93UhSj1oq5uGMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UhSj1oq5uGMeta?.name ?? "products-slug___pl",
    path: _91slug_93UhSj1oq5uGMeta?.path ?? "/pl/products/:slug()",
    meta: _91slug_93UhSj1oq5uGMeta || {},
    alias: _91slug_93UhSj1oq5uGMeta?.alias || [],
    redirect: _91slug_93UhSj1oq5uGMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexBALeFK7cDEMeta?.name ?? "products___de",
    path: indexBALeFK7cDEMeta?.path ?? "/de/products",
    meta: indexBALeFK7cDEMeta || {},
    alias: indexBALeFK7cDEMeta?.alias || [],
    redirect: indexBALeFK7cDEMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexBALeFK7cDEMeta?.name ?? "products___en",
    path: indexBALeFK7cDEMeta?.path ?? "/en/products",
    meta: indexBALeFK7cDEMeta || {},
    alias: indexBALeFK7cDEMeta?.alias || [],
    redirect: indexBALeFK7cDEMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexBALeFK7cDEMeta?.name ?? "products___fr",
    path: indexBALeFK7cDEMeta?.path ?? "/fr/products",
    meta: indexBALeFK7cDEMeta || {},
    alias: indexBALeFK7cDEMeta?.alias || [],
    redirect: indexBALeFK7cDEMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexBALeFK7cDEMeta?.name ?? "products___cs",
    path: indexBALeFK7cDEMeta?.path ?? "/cs/products",
    meta: indexBALeFK7cDEMeta || {},
    alias: indexBALeFK7cDEMeta?.alias || [],
    redirect: indexBALeFK7cDEMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexBALeFK7cDEMeta?.name ?? "products___it",
    path: indexBALeFK7cDEMeta?.path ?? "/it/products",
    meta: indexBALeFK7cDEMeta || {},
    alias: indexBALeFK7cDEMeta?.alias || [],
    redirect: indexBALeFK7cDEMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexBALeFK7cDEMeta?.name ?? "products___pl",
    path: indexBALeFK7cDEMeta?.path ?? "/pl/products",
    meta: indexBALeFK7cDEMeta || {},
    alias: indexBALeFK7cDEMeta?.alias || [],
    redirect: indexBALeFK7cDEMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: redirect_45to_45paypal2SSWCgGFSdMeta?.name ?? "redirect-to-paypal___de",
    path: redirect_45to_45paypal2SSWCgGFSdMeta?.path ?? "/de/redirect-to-paypal",
    meta: redirect_45to_45paypal2SSWCgGFSdMeta || {},
    alias: redirect_45to_45paypal2SSWCgGFSdMeta?.alias || [],
    redirect: redirect_45to_45paypal2SSWCgGFSdMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/redirect-to-paypal.vue").then(m => m.default || m)
  },
  {
    name: redirect_45to_45paypal2SSWCgGFSdMeta?.name ?? "redirect-to-paypal___en",
    path: redirect_45to_45paypal2SSWCgGFSdMeta?.path ?? "/en/redirect-to-paypal",
    meta: redirect_45to_45paypal2SSWCgGFSdMeta || {},
    alias: redirect_45to_45paypal2SSWCgGFSdMeta?.alias || [],
    redirect: redirect_45to_45paypal2SSWCgGFSdMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/redirect-to-paypal.vue").then(m => m.default || m)
  },
  {
    name: redirect_45to_45paypal2SSWCgGFSdMeta?.name ?? "redirect-to-paypal___fr",
    path: redirect_45to_45paypal2SSWCgGFSdMeta?.path ?? "/fr/redirect-to-paypal",
    meta: redirect_45to_45paypal2SSWCgGFSdMeta || {},
    alias: redirect_45to_45paypal2SSWCgGFSdMeta?.alias || [],
    redirect: redirect_45to_45paypal2SSWCgGFSdMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/redirect-to-paypal.vue").then(m => m.default || m)
  },
  {
    name: redirect_45to_45paypal2SSWCgGFSdMeta?.name ?? "redirect-to-paypal___cs",
    path: redirect_45to_45paypal2SSWCgGFSdMeta?.path ?? "/cs/redirect-to-paypal",
    meta: redirect_45to_45paypal2SSWCgGFSdMeta || {},
    alias: redirect_45to_45paypal2SSWCgGFSdMeta?.alias || [],
    redirect: redirect_45to_45paypal2SSWCgGFSdMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/redirect-to-paypal.vue").then(m => m.default || m)
  },
  {
    name: redirect_45to_45paypal2SSWCgGFSdMeta?.name ?? "redirect-to-paypal___it",
    path: redirect_45to_45paypal2SSWCgGFSdMeta?.path ?? "/it/redirect-to-paypal",
    meta: redirect_45to_45paypal2SSWCgGFSdMeta || {},
    alias: redirect_45to_45paypal2SSWCgGFSdMeta?.alias || [],
    redirect: redirect_45to_45paypal2SSWCgGFSdMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/redirect-to-paypal.vue").then(m => m.default || m)
  },
  {
    name: redirect_45to_45paypal2SSWCgGFSdMeta?.name ?? "redirect-to-paypal___pl",
    path: redirect_45to_45paypal2SSWCgGFSdMeta?.path ?? "/pl/redirect-to-paypal",
    meta: redirect_45to_45paypal2SSWCgGFSdMeta || {},
    alias: redirect_45to_45paypal2SSWCgGFSdMeta?.alias || [],
    redirect: redirect_45to_45paypal2SSWCgGFSdMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/redirect-to-paypal.vue").then(m => m.default || m)
  },
  {
    name: sample_45corrugated_45cardboardbq31GwdGTVMeta?.name ?? "sample-corrugated-cardboard___de",
    path: sample_45corrugated_45cardboardbq31GwdGTVMeta?.path ?? "/de/sample-corrugated-cardboard",
    meta: sample_45corrugated_45cardboardbq31GwdGTVMeta || {},
    alias: sample_45corrugated_45cardboardbq31GwdGTVMeta?.alias || [],
    redirect: sample_45corrugated_45cardboardbq31GwdGTVMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sample-corrugated-cardboard.vue").then(m => m.default || m)
  },
  {
    name: sample_45corrugated_45cardboardbq31GwdGTVMeta?.name ?? "sample-corrugated-cardboard___en",
    path: sample_45corrugated_45cardboardbq31GwdGTVMeta?.path ?? "/en/sample-corrugated-cardboard",
    meta: sample_45corrugated_45cardboardbq31GwdGTVMeta || {},
    alias: sample_45corrugated_45cardboardbq31GwdGTVMeta?.alias || [],
    redirect: sample_45corrugated_45cardboardbq31GwdGTVMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sample-corrugated-cardboard.vue").then(m => m.default || m)
  },
  {
    name: sample_45corrugated_45cardboardbq31GwdGTVMeta?.name ?? "sample-corrugated-cardboard___fr",
    path: sample_45corrugated_45cardboardbq31GwdGTVMeta?.path ?? "/fr/sample-corrugated-cardboard",
    meta: sample_45corrugated_45cardboardbq31GwdGTVMeta || {},
    alias: sample_45corrugated_45cardboardbq31GwdGTVMeta?.alias || [],
    redirect: sample_45corrugated_45cardboardbq31GwdGTVMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sample-corrugated-cardboard.vue").then(m => m.default || m)
  },
  {
    name: sample_45corrugated_45cardboardbq31GwdGTVMeta?.name ?? "sample-corrugated-cardboard___cs",
    path: sample_45corrugated_45cardboardbq31GwdGTVMeta?.path ?? "/cs/sample-corrugated-cardboard",
    meta: sample_45corrugated_45cardboardbq31GwdGTVMeta || {},
    alias: sample_45corrugated_45cardboardbq31GwdGTVMeta?.alias || [],
    redirect: sample_45corrugated_45cardboardbq31GwdGTVMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sample-corrugated-cardboard.vue").then(m => m.default || m)
  },
  {
    name: sample_45corrugated_45cardboardbq31GwdGTVMeta?.name ?? "sample-corrugated-cardboard___it",
    path: sample_45corrugated_45cardboardbq31GwdGTVMeta?.path ?? "/it/sample-corrugated-cardboard",
    meta: sample_45corrugated_45cardboardbq31GwdGTVMeta || {},
    alias: sample_45corrugated_45cardboardbq31GwdGTVMeta?.alias || [],
    redirect: sample_45corrugated_45cardboardbq31GwdGTVMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sample-corrugated-cardboard.vue").then(m => m.default || m)
  },
  {
    name: sample_45corrugated_45cardboardbq31GwdGTVMeta?.name ?? "sample-corrugated-cardboard___pl",
    path: sample_45corrugated_45cardboardbq31GwdGTVMeta?.path ?? "/pl/sample-corrugated-cardboard",
    meta: sample_45corrugated_45cardboardbq31GwdGTVMeta || {},
    alias: sample_45corrugated_45cardboardbq31GwdGTVMeta?.alias || [],
    redirect: sample_45corrugated_45cardboardbq31GwdGTVMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sample-corrugated-cardboard.vue").then(m => m.default || m)
  },
  {
    name: sampleVA9t0Y69h6Meta?.name ?? "sample___de",
    path: sampleVA9t0Y69h6Meta?.path ?? "/de/sample",
    meta: sampleVA9t0Y69h6Meta || {},
    alias: sampleVA9t0Y69h6Meta?.alias || [],
    redirect: sampleVA9t0Y69h6Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/sample.vue").then(m => m.default || m)
  },
  {
    name: sampleVA9t0Y69h6Meta?.name ?? "sample___en",
    path: sampleVA9t0Y69h6Meta?.path ?? "/en/sample",
    meta: sampleVA9t0Y69h6Meta || {},
    alias: sampleVA9t0Y69h6Meta?.alias || [],
    redirect: sampleVA9t0Y69h6Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/sample.vue").then(m => m.default || m)
  },
  {
    name: sampleVA9t0Y69h6Meta?.name ?? "sample___fr",
    path: sampleVA9t0Y69h6Meta?.path ?? "/fr/sample",
    meta: sampleVA9t0Y69h6Meta || {},
    alias: sampleVA9t0Y69h6Meta?.alias || [],
    redirect: sampleVA9t0Y69h6Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/sample.vue").then(m => m.default || m)
  },
  {
    name: sampleVA9t0Y69h6Meta?.name ?? "sample___cs",
    path: sampleVA9t0Y69h6Meta?.path ?? "/cs/sample",
    meta: sampleVA9t0Y69h6Meta || {},
    alias: sampleVA9t0Y69h6Meta?.alias || [],
    redirect: sampleVA9t0Y69h6Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/sample.vue").then(m => m.default || m)
  },
  {
    name: sampleVA9t0Y69h6Meta?.name ?? "sample___it",
    path: sampleVA9t0Y69h6Meta?.path ?? "/it/sample",
    meta: sampleVA9t0Y69h6Meta || {},
    alias: sampleVA9t0Y69h6Meta?.alias || [],
    redirect: sampleVA9t0Y69h6Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/sample.vue").then(m => m.default || m)
  },
  {
    name: sampleVA9t0Y69h6Meta?.name ?? "sample___pl",
    path: sampleVA9t0Y69h6Meta?.path ?? "/pl/sample",
    meta: sampleVA9t0Y69h6Meta || {},
    alias: sampleVA9t0Y69h6Meta?.alias || [],
    redirect: sampleVA9t0Y69h6Meta?.redirect,
    component: () => import("/usr/src/webshop/pages/sample.vue").then(m => m.default || m)
  },
  {
    name: sampletube9hKvUuyhbuMeta?.name ?? "sampletube___de",
    path: sampletube9hKvUuyhbuMeta?.path ?? "/de/sampletube",
    meta: sampletube9hKvUuyhbuMeta || {},
    alias: sampletube9hKvUuyhbuMeta?.alias || [],
    redirect: sampletube9hKvUuyhbuMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sampletube.vue").then(m => m.default || m)
  },
  {
    name: sampletube9hKvUuyhbuMeta?.name ?? "sampletube___en",
    path: sampletube9hKvUuyhbuMeta?.path ?? "/en/sampletube",
    meta: sampletube9hKvUuyhbuMeta || {},
    alias: sampletube9hKvUuyhbuMeta?.alias || [],
    redirect: sampletube9hKvUuyhbuMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sampletube.vue").then(m => m.default || m)
  },
  {
    name: sampletube9hKvUuyhbuMeta?.name ?? "sampletube___fr",
    path: sampletube9hKvUuyhbuMeta?.path ?? "/fr/sampletube",
    meta: sampletube9hKvUuyhbuMeta || {},
    alias: sampletube9hKvUuyhbuMeta?.alias || [],
    redirect: sampletube9hKvUuyhbuMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sampletube.vue").then(m => m.default || m)
  },
  {
    name: sampletube9hKvUuyhbuMeta?.name ?? "sampletube___cs",
    path: sampletube9hKvUuyhbuMeta?.path ?? "/cs/sampletube",
    meta: sampletube9hKvUuyhbuMeta || {},
    alias: sampletube9hKvUuyhbuMeta?.alias || [],
    redirect: sampletube9hKvUuyhbuMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sampletube.vue").then(m => m.default || m)
  },
  {
    name: sampletube9hKvUuyhbuMeta?.name ?? "sampletube___it",
    path: sampletube9hKvUuyhbuMeta?.path ?? "/it/sampletube",
    meta: sampletube9hKvUuyhbuMeta || {},
    alias: sampletube9hKvUuyhbuMeta?.alias || [],
    redirect: sampletube9hKvUuyhbuMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sampletube.vue").then(m => m.default || m)
  },
  {
    name: sampletube9hKvUuyhbuMeta?.name ?? "sampletube___pl",
    path: sampletube9hKvUuyhbuMeta?.path ?? "/pl/sampletube",
    meta: sampletube9hKvUuyhbuMeta || {},
    alias: sampletube9hKvUuyhbuMeta?.alias || [],
    redirect: sampletube9hKvUuyhbuMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sampletube.vue").then(m => m.default || m)
  },
  {
    name: sitemapQ4FiwpGrEUMeta?.name ?? "sitemap___de",
    path: sitemapQ4FiwpGrEUMeta?.path ?? "/de/sitemap",
    meta: sitemapQ4FiwpGrEUMeta || {},
    alias: sitemapQ4FiwpGrEUMeta?.alias || [],
    redirect: sitemapQ4FiwpGrEUMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapQ4FiwpGrEUMeta?.name ?? "sitemap___en",
    path: sitemapQ4FiwpGrEUMeta?.path ?? "/en/sitemap",
    meta: sitemapQ4FiwpGrEUMeta || {},
    alias: sitemapQ4FiwpGrEUMeta?.alias || [],
    redirect: sitemapQ4FiwpGrEUMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapQ4FiwpGrEUMeta?.name ?? "sitemap___fr",
    path: sitemapQ4FiwpGrEUMeta?.path ?? "/fr/sitemap",
    meta: sitemapQ4FiwpGrEUMeta || {},
    alias: sitemapQ4FiwpGrEUMeta?.alias || [],
    redirect: sitemapQ4FiwpGrEUMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapQ4FiwpGrEUMeta?.name ?? "sitemap___cs",
    path: sitemapQ4FiwpGrEUMeta?.path ?? "/cs/sitemap",
    meta: sitemapQ4FiwpGrEUMeta || {},
    alias: sitemapQ4FiwpGrEUMeta?.alias || [],
    redirect: sitemapQ4FiwpGrEUMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapQ4FiwpGrEUMeta?.name ?? "sitemap___it",
    path: sitemapQ4FiwpGrEUMeta?.path ?? "/it/sitemap",
    meta: sitemapQ4FiwpGrEUMeta || {},
    alias: sitemapQ4FiwpGrEUMeta?.alias || [],
    redirect: sitemapQ4FiwpGrEUMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapQ4FiwpGrEUMeta?.name ?? "sitemap___pl",
    path: sitemapQ4FiwpGrEUMeta?.path ?? "/pl/sitemap",
    meta: sitemapQ4FiwpGrEUMeta || {},
    alias: sitemapQ4FiwpGrEUMeta?.alias || [],
    redirect: sitemapQ4FiwpGrEUMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityv05wKT300EMeta?.name ?? "sustainability___de",
    path: sustainabilityv05wKT300EMeta?.path ?? "/de/sustainability",
    meta: sustainabilityv05wKT300EMeta || {},
    alias: sustainabilityv05wKT300EMeta?.alias || [],
    redirect: sustainabilityv05wKT300EMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityv05wKT300EMeta?.name ?? "sustainability___en",
    path: sustainabilityv05wKT300EMeta?.path ?? "/en/sustainability",
    meta: sustainabilityv05wKT300EMeta || {},
    alias: sustainabilityv05wKT300EMeta?.alias || [],
    redirect: sustainabilityv05wKT300EMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityv05wKT300EMeta?.name ?? "sustainability___fr",
    path: sustainabilityv05wKT300EMeta?.path ?? "/fr/sustainability",
    meta: sustainabilityv05wKT300EMeta || {},
    alias: sustainabilityv05wKT300EMeta?.alias || [],
    redirect: sustainabilityv05wKT300EMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityv05wKT300EMeta?.name ?? "sustainability___cs",
    path: sustainabilityv05wKT300EMeta?.path ?? "/cs/sustainability",
    meta: sustainabilityv05wKT300EMeta || {},
    alias: sustainabilityv05wKT300EMeta?.alias || [],
    redirect: sustainabilityv05wKT300EMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityv05wKT300EMeta?.name ?? "sustainability___it",
    path: sustainabilityv05wKT300EMeta?.path ?? "/it/sustainability",
    meta: sustainabilityv05wKT300EMeta || {},
    alias: sustainabilityv05wKT300EMeta?.alias || [],
    redirect: sustainabilityv05wKT300EMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityv05wKT300EMeta?.name ?? "sustainability___pl",
    path: sustainabilityv05wKT300EMeta?.path ?? "/pl/sustainability",
    meta: sustainabilityv05wKT300EMeta || {},
    alias: sustainabilityv05wKT300EMeta?.alias || [],
    redirect: sustainabilityv05wKT300EMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: swiss_45fairWuCOb43gXvMeta?.name ?? "swiss-fair___de",
    path: swiss_45fairWuCOb43gXvMeta?.path ?? "/de/swiss-fair",
    meta: swiss_45fairWuCOb43gXvMeta || {},
    alias: swiss_45fairWuCOb43gXvMeta?.alias || [],
    redirect: swiss_45fairWuCOb43gXvMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/swiss-fair.vue").then(m => m.default || m)
  },
  {
    name: swiss_45fairWuCOb43gXvMeta?.name ?? "swiss-fair___en",
    path: swiss_45fairWuCOb43gXvMeta?.path ?? "/en/swiss-fair",
    meta: swiss_45fairWuCOb43gXvMeta || {},
    alias: swiss_45fairWuCOb43gXvMeta?.alias || [],
    redirect: swiss_45fairWuCOb43gXvMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/swiss-fair.vue").then(m => m.default || m)
  },
  {
    name: swiss_45fairWuCOb43gXvMeta?.name ?? "swiss-fair___fr",
    path: swiss_45fairWuCOb43gXvMeta?.path ?? "/fr/swiss-fair",
    meta: swiss_45fairWuCOb43gXvMeta || {},
    alias: swiss_45fairWuCOb43gXvMeta?.alias || [],
    redirect: swiss_45fairWuCOb43gXvMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/swiss-fair.vue").then(m => m.default || m)
  },
  {
    name: swiss_45fairWuCOb43gXvMeta?.name ?? "swiss-fair___cs",
    path: swiss_45fairWuCOb43gXvMeta?.path ?? "/cs/swiss-fair",
    meta: swiss_45fairWuCOb43gXvMeta || {},
    alias: swiss_45fairWuCOb43gXvMeta?.alias || [],
    redirect: swiss_45fairWuCOb43gXvMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/swiss-fair.vue").then(m => m.default || m)
  },
  {
    name: swiss_45fairWuCOb43gXvMeta?.name ?? "swiss-fair___it",
    path: swiss_45fairWuCOb43gXvMeta?.path ?? "/it/swiss-fair",
    meta: swiss_45fairWuCOb43gXvMeta || {},
    alias: swiss_45fairWuCOb43gXvMeta?.alias || [],
    redirect: swiss_45fairWuCOb43gXvMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/swiss-fair.vue").then(m => m.default || m)
  },
  {
    name: swiss_45fairWuCOb43gXvMeta?.name ?? "swiss-fair___pl",
    path: swiss_45fairWuCOb43gXvMeta?.path ?? "/pl/swiss-fair",
    meta: swiss_45fairWuCOb43gXvMeta || {},
    alias: swiss_45fairWuCOb43gXvMeta?.alias || [],
    redirect: swiss_45fairWuCOb43gXvMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/swiss-fair.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45servicekkgjABgitLMeta?.name ?? "terms-of-service___de",
    path: terms_45of_45servicekkgjABgitLMeta?.path ?? "/de/terms-of-service",
    meta: terms_45of_45servicekkgjABgitLMeta || {},
    alias: terms_45of_45servicekkgjABgitLMeta?.alias || [],
    redirect: terms_45of_45servicekkgjABgitLMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45servicekkgjABgitLMeta?.name ?? "terms-of-service___en",
    path: terms_45of_45servicekkgjABgitLMeta?.path ?? "/en/terms-of-service",
    meta: terms_45of_45servicekkgjABgitLMeta || {},
    alias: terms_45of_45servicekkgjABgitLMeta?.alias || [],
    redirect: terms_45of_45servicekkgjABgitLMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45servicekkgjABgitLMeta?.name ?? "terms-of-service___fr",
    path: terms_45of_45servicekkgjABgitLMeta?.path ?? "/fr/terms-of-service",
    meta: terms_45of_45servicekkgjABgitLMeta || {},
    alias: terms_45of_45servicekkgjABgitLMeta?.alias || [],
    redirect: terms_45of_45servicekkgjABgitLMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45servicekkgjABgitLMeta?.name ?? "terms-of-service___cs",
    path: terms_45of_45servicekkgjABgitLMeta?.path ?? "/cs/terms-of-service",
    meta: terms_45of_45servicekkgjABgitLMeta || {},
    alias: terms_45of_45servicekkgjABgitLMeta?.alias || [],
    redirect: terms_45of_45servicekkgjABgitLMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45servicekkgjABgitLMeta?.name ?? "terms-of-service___it",
    path: terms_45of_45servicekkgjABgitLMeta?.path ?? "/it/terms-of-service",
    meta: terms_45of_45servicekkgjABgitLMeta || {},
    alias: terms_45of_45servicekkgjABgitLMeta?.alias || [],
    redirect: terms_45of_45servicekkgjABgitLMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45servicekkgjABgitLMeta?.name ?? "terms-of-service___pl",
    path: terms_45of_45servicekkgjABgitLMeta?.path ?? "/pl/terms-of-service",
    meta: terms_45of_45servicekkgjABgitLMeta || {},
    alias: terms_45of_45servicekkgjABgitLMeta?.alias || [],
    redirect: terms_45of_45servicekkgjABgitLMeta?.redirect,
    component: () => import("/usr/src/webshop/pages/terms-of-service.vue").then(m => m.default || m)
  }
]