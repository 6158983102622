import revive_payload_client_4sVQNw7RlN from "/usr/src/webshop/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/webshop/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/webshop/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/webshop/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/webshop/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/webshop/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/webshop/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/webshop/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import recaptcha_sRc6yj4fw7 from "/usr/src/webshop/node_modules/@a4l-lp24/core/dist/runtime/plugins/recaptcha.mjs";
import vueformgenerator_XqO4Zvqz0Q from "/usr/src/webshop/node_modules/@a4l-lp24/core/dist/runtime/plugins/vueformgenerator.mjs";
import toast_BskldySFLj from "/usr/src/webshop/node_modules/@a4l-lp24/core/dist/runtime/plugins/toast.mjs";
import components_PA9lTXQkR7 from "/usr/src/webshop/node_modules/@a4l-lp24/core/dist/runtime/plugins/components.mjs";
import translations_YlZANCJBP6 from "/usr/src/webshop/node_modules/@a4l-lp24/core/dist/runtime/plugins/translations.mjs";
import plugin_tbFNToZNim from "/usr/src/webshop/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_AOuQ1DYzjk from "/usr/src/webshop/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import nuxt_plugin_03YjkxYbK5 from "/usr/src/webshop/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import i18n_yfWm7jX06p from "/usr/src/webshop/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/src/webshop/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import token_validator_6bVSeqN21e from "/usr/src/webshop/plugins/token-validator.ts";
import vee_validate_rules_WAudwGNGnr from "/usr/src/webshop/plugins/vee-validate-rules.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  recaptcha_sRc6yj4fw7,
  vueformgenerator_XqO4Zvqz0Q,
  toast_BskldySFLj,
  components_PA9lTXQkR7,
  translations_YlZANCJBP6,
  plugin_tbFNToZNim,
  plugin_AOuQ1DYzjk,
  nuxt_plugin_03YjkxYbK5,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  token_validator_6bVSeqN21e,
  vee_validate_rules_WAudwGNGnr
]